import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {getRoleDataForDropDown} from '../../role/redux/RoleCRUD'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'

interface IRenderFormikForm {
  values: IKYCFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IKYCFormValues {
  adminId: string
  flag: string
  rejection_reason: string
}

interface IEditClientProps {
  onSubmit: any
  data: any
  handleCancel?: any
}

export class EditKYCForm extends React.Component<IEditClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      roleData: [],
    }
  }


  public validateForm = (values: IKYCFormValues) => {
    const errors: any = {}

    if (!values.adminId) {
      errors.adminId = 'Admin Id is required'
    }

    if (!values.flag) {
      errors.flag = 'KYC Status is required'
    }

    if (values.flag && values.flag == 'false' && !values.rejection_reason) {
      errors.rejection_reason = 'Rejection Reason is required'
    }

    return errors
  }

  public handleSubmit = (values: IKYCFormValues, action: any) => {
    const {data, handleCancel, onSubmit} = this.props
    
    onSubmit(values)
    handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    const {data} = this.props
    const {roleData} = this.state

    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  adminId: data._id ? data._id : '',
                  rejection_reason: data.rejection_reason ? data.rejection_reason : '',
                  flag: data.is_kyc_verified ? "true": 'false',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div>
                      <div>
                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputSingleSelect
                              input={{
                                value: values.flag,
                                id: 'flag',
                                name: 'flag',
                                options: [
                                  {
                                    value: 'true',
                                    label: 'Approve',
                                  },
                                  {
                                    value: 'false',
                                    label: 'Reject',
                                  },
                                ],
                              }}
                              placeholder='Select KYC Status'
                              onChangeMethod={(value) => {
                                setFieldValue('flag', value)
                              }}
                              label='Select KYC Status'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                         {values.flag && values.flag == "false" && <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.rejection_reason,
                                id: 'rejection_reason',
                                name: 'rejection_reason',
                              }}
                              placeholder='Enter Rejection Reason'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('rejection_reason', value)
                              }}
                              onBlur={handleBlur}
                              label='Rejection Reason'
                              error={errors}
                              touched={touched}
                            />
                          </div>}
                        </div>
                      </div>

                      <div className='custom-card-footer'>
                        <div className='float-right'>
                          <InputButtonComponent onClick={handleSubmit}>Save</InputButtonComponent>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
