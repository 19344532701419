/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getPendingKycData} from '../redux/KycCRUD'
import {useDispatch} from 'react-redux'
import * as kyc from '../redux/KycRedux'
import ListKycData from './ListKycData'
import {Tabs, Spin} from 'antd'
const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const [tabKey, setTabKey] = useState('1')
  const [isLoading, setIsLoading] = useState(false)

  const callPendingKycData = async (key: string) => {
    try {
      setIsLoading(true)
      let adminData = await getPendingKycData(0, 10, key)
      if (adminData.data) {
        await dispatch(kyc.actions.setKycLoading(true))
        // once kyc data set then loading will automatically off using saga middleware
        await dispatch(kyc.actions.setKycData(adminData.data.docs))
        await dispatch(kyc.actions.setKycDataTotal(adminData.data.total))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
    finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    callPendingKycData('pending_approval')
  }, [])

  const onChangeTab = (key: string) => {
    console.log(key, typeof key, 'key')
    setTabKey(key)
    if(key === '3') {
      callPendingKycData('apikey_expired')
    }
    else if (key === '2') {
      callPendingKycData('pending_upload')
    } else {
      callPendingKycData('pending_approval')
    }
  }

  return (
    <div>
      <Spin spinning={isLoading} tip='Loading...'>
        {/* <ListKycData /> */}
        <Tabs defaultActiveKey='1' onChange={onChangeTab} type='card'>
          <TabPane tab='Pending Approval' key='1'>
            {tabKey === '1' && <ListKycData tabKey='pending_approval' />}
          </TabPane>
          <TabPane tab='Pending Upload' key='2'>
            {tabKey === '2' && <ListKycData tabKey='pending_upload' />}
          </TabPane>
          <TabPane tab='ApiKey Expired' key='3'>
            {tabKey === '3' && <ListKycData tabKey='apikey_expired' />}
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/kyc/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/kyc/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.PENDINGKYC'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
