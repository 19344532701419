/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createApiKeyData, getApiKeyData} from '../redux/ApiKeyCRUD'
import * as apiKey from '../redux/ApiKeyRedux'
import {CreateApiKeyForm} from './CreateApiKeyForm'
import {IApiKeyFormValues} from './CreateApiKeyForm'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callApiKeyData = async () => {
    try {
      let apiKeyData = await getApiKeyData(0, 10)
      if (apiKeyData.data) {
        await dispatch(apiKey.actions.setApiKeyLoading(true))
        // once api key data set then loading will automatically off using saga middleware
        await dispatch(apiKey.actions.setApiKeyData(apiKeyData.data.docs))
        await dispatch(apiKey.actions.setApiKeyDataTotal(apiKeyData.data.total))
        history.push('/apiKey/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IApiKeyFormValues) => {
    try {
      await createApiKeyData(values)
      await callApiKeyData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <CreateApiKeyForm onSubmit={(values: IApiKeyFormValues) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Create',
            path: '/apiKey/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'Create',
            path: '/apiKey/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.APIKEY'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
