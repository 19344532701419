import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_PENDING_KYC_DATA_URL = `${API_URL}v1/auth/admin/`

// Get Role Data
export async function getPendingKycData(startIndex: number, limitNumber: number,key: string, searchText?: string) {
  try {
 let urlPath = ''
 if(key == 'pending_approval') {
    urlPath = `${GET_PENDING_KYC_DATA_URL}kyc/pending/approval/${startIndex}/${limitNumber}?searchText=${searchText ? searchText : ''}`
 }
 else if(key == 'pending_upload') {
    urlPath = `${GET_PENDING_KYC_DATA_URL}kyc/pending/upload/${startIndex}/${limitNumber}?searchText=${searchText ? searchText : ''}`
 }
 else if (key == 'apikey_expired') {
    urlPath = `${GET_PENDING_KYC_DATA_URL}apikey/expired/${startIndex}/${limitNumber}?searchText=${searchText ? searchText : ''}`
 }
 let adminData = await axios.get(`${urlPath}`)
 return adminData
  } catch (e) {
    throw e
  }
}
