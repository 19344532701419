/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Select from 'react-select'
import _ from 'lodash'

type Props = {
  onChangeMethod: (value: any) => void
  placeholder?: string
  label?: string
  input: {
    value?: string
    name: string
    id: string
    options: Array<{
      label: string
      value: string | boolean
    }>
  }
  iconFontClass?: string
  type?: string
  disabled?: boolean
  className?: string
  error: any
  touched: any
  required?: boolean
  searchable?: boolean
  clearable?: boolean
  maxMenuHeight?: number
}

const InputSingleSelect: React.FC<Props> = ({
  onChangeMethod,
  placeholder,
  label,
  input,
  iconFontClass,
  type = 'text',
  disabled = false,
  className,
  error,
  touched,
  required = true,
  searchable = true,
  clearable = true,
  maxMenuHeight,
}) => {
  return (
    <>
      {label && (
        <label>
          {label} {required && <span className='text-danger'>*</span>}
        </label>
      )}

      <>
        <Select
          {...input}
          id={input.id}
          name={input.name}
          options={input.options}
          placeholder={placeholder}
          value={
            typeof input.value === 'string' ||
            typeof input.value === 'number' ||
            typeof input.value === 'boolean'
              ? input.options.filter((option: any) => option.value === input.value)
              : input.value
          }
          onChange={(option: any) => {
            console.log('onchange = ', option)
            onChangeMethod(option ? option.value : undefined)
          }}
          onBlur={(event: any) => {
            // console.log('onblur = ', option);
            // props.input.onBlur(props.input.value);
            event.preventDefault()
          }}
          // autoFocus
          isSimpleValue
          isSearchable={searchable !== undefined ? searchable : false}
          isClearable={clearable !== undefined ? clearable : false}
          className={className}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
          maxMenuHeight={maxMenuHeight}
          isDisabled={disabled}
        />
        {touched && error && error[input.name] && !disabled && (
          <div className='fv-plugins-message-container text-danger'>
            <div className='fv-help-block'>{error[input.name]}</div>
          </div>
        )}
      </>
    </>
  )
}

export {InputSingleSelect}

// <InputSingleSelect
//   input={{
//     value: values.bank_select_name,
//     id: 'bank_select_name',
//     name: 'bank_select_name',
//     options: [
//       {
//         label: 'option1',
//         value: '1',
//       },
//       {
//         label: 'option2',
//         value: '2',
//       },
//     ],
//   }}
//   placeholder='Enter bank Select name'
//   onChangeMethod={(value) => {
//     setFieldValue('bank_select_name', value)
//   }}
//   label='Bank Select Name'
//   error={errors}
//   touched={touched}
// />
