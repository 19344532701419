import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {BuyRequestModal} from '../models/BuyRequestModal'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setBuyRequestData: 'SET_BUY_REQUEST_DATA',
  setBuyRequestDataTotal: 'SET_BUY_REQUEST_DATA_TOTAL',
  setBuyRequestLoading: 'SET_BUY_REQUEST_LOADER',
}

const initialBuyRequestState: IBuyRequestState = {
  buyRequestData: undefined,
  buyRequestDataTotal: undefined,
  buyRequestLoading: false,
}

export interface IBuyRequestState {
  buyRequestData?: BuyRequestModal
  buyRequestDataTotal?: number
  buyRequestLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'buyRequest', whitelist: ['buyRequestData', 'buyRequestDataTotal']},
  (
    state: IBuyRequestState = initialBuyRequestState,
    action: ActionWithPayload<IBuyRequestState>
  ) => {
    switch (action.type) {
      case actionTypes.setBuyRequestData: {
        const buyRequestData = action.payload?.buyRequestData
        return {...state, buyRequestData}
      }

      case actionTypes.setBuyRequestDataTotal: {
        const buyRequestDataTotal = action.payload?.buyRequestDataTotal
        return {...state, buyRequestDataTotal}
      }

      case actionTypes.setBuyRequestLoading: {
        const buyRequestLoading = action.payload?.buyRequestLoading
        return {...state, buyRequestLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setBuyRequestData: (buyRequestData: Array<BuyRequestModal>) => ({
    type: actionTypes.setBuyRequestData,
    payload: {buyRequestData: buyRequestData},
  }),
  setBuyRequestDataTotal: (buyRequestDataTotal: number) => ({
    type: actionTypes.setBuyRequestDataTotal,
    payload: {buyRequestDataTotal},
  }),
  setBuyRequestLoading: (buyRequestLoading: boolean) => ({
    type: actionTypes.setBuyRequestLoading,
    payload: {buyRequestLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setBuyRequestData, function* loginSaga() {
    yield put(actions.setBuyRequestLoading(false))
  })
}
