import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {RoleModal} from '../models/RoleModal'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setRoleData: 'SET_ROLE_DATA',
  setRoleDataTotal: 'SET_ROLE_DATA_TOTAL',
  setRoleLoading: 'SET_ROLE_LOADER'
}

const initialClientState: IRoleState = {
  roleData: undefined,
  roleDataTotal: undefined,
  roleLoading: false
}

export interface IRoleState {
  roleData?: RoleModal
  roleDataTotal?: number
  roleLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'role', whitelist: ['roleData', 'roleDataTotal']},
    (state: IRoleState = initialClientState, action: ActionWithPayload<IRoleState>) => {
    switch (action.type) {
      case actionTypes.setRoleData: {
        const roleData = action.payload?.roleData
        return {...state, roleData}
      }
            
      case actionTypes.setRoleDataTotal: {
        const roleDataTotal = action.payload?.roleDataTotal
        return {...state, roleDataTotal}
      }
        
      case actionTypes.setRoleLoading: {
        const roleLoading = action.payload?.roleLoading
        return {...state, roleLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setRoleData: (roleData: Array<RoleModal>) => ({type: actionTypes.setRoleData, payload: {roleData : roleData}}),
  setRoleDataTotal: (roleDataTotal: number) => ({ type: actionTypes.setRoleDataTotal, payload: { roleDataTotal } }),
  setRoleLoading: (roleLoading: boolean) => ({type: actionTypes.setRoleLoading, payload: {roleLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setRoleData, function* setRoleData() {
    yield put(actions.setRoleLoading(false))
  })
}
