/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as admin from '../redux/AdminRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import { Button } from 'antd';
import moment from 'moment';
import {EditOutlined, UploadOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {
  getAdminData,
  getSearchAdminData,
  changeStatusAdminData,
  kycDocumentUpload,
} from '../redux/AdminCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {CommanModal} from '../../../modules/comman/components/Modal'
import {EditKYCForm} from './EditKYCForm'
import {UploadKycDocumentForm} from './UploadKycDocumentForm'
import {Tag} from 'antd'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor

interface IRecordsTableProps {
  adminData: any
  adminDataTotal: number
  adminLoading: boolean
  tabKey?: string
  setListAdminData: (adminData: any) => void
  setListAdminDataTotal: (adminDataTotal: number) => void
  setAdminLoading: (adminLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  adminSelectedData: any
  uploadKycDocumentState: any
}

type PathParamsType = {
  param1: string
}
class ListRoleData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      adminSelectedData: null,
      uploadKycDocumentState: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListAdminData(pagination)
    )
  }

  public getListAdminData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listAdminSearchData(start, end)
        } else {
          this.listAdminData(start, end)
        }
      }
    )
  }

  public listAdminData = async (start: number, end: any) => {
    const {setAdminLoading} = this.props
    try {
      const {setListAdminData, setListAdminDataTotal,tabKey} = this.props
      await setAdminLoading(true)
      let adminData = await getAdminData(start, end,tabKey ? tabKey : '')
      if (adminData.data) {
        await setListAdminData(adminData.data.docs)
        await setListAdminDataTotal(adminData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setAdminLoading(false)
    }
  }

  public listAdminSearchData = async (start: number, end: any) => {
    const {setAdminLoading} = this.props
    try {
      const {setListAdminData, setListAdminDataTotal,tabKey} = this.props
      const {searchText} = this.state

      await setAdminLoading(true)
      let adminData = await getSearchAdminData(start, end, searchText,tabKey ? tabKey : '')
      if (adminData.data) {
        await setListAdminData(adminData.data.docs)
        await setListAdminDataTotal(adminData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setAdminLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listAdminSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  //   public onChangeStatus = async (item: any, checked: boolean) => {
  //   const {setAdminLoading} = this.props
  //   try {
  //     const {startIndex, limitNumber, searchText} = this.state

  //     await setAdminLoading(true)
  //     await changeStatusAdminData(item._id, checked)

  //     Toaster({
  //       type: 'success',
  //       title: 'ADMIN',
  //       description: 'Status changed successFully',
  //     })

  //     if (searchText != null && searchText !== '') {
  //       this.listAdminSearchData(startIndex, limitNumber)
  //       return
  //     }
  //     this.listAdminData(startIndex, limitNumber)
  //   } catch (e) {
  //     console.log('!!!!Error', e)
  //   } finally {
  //     await setAdminLoading(false)
  //   }
  // }

  public onCreateRole = () => {
    const {history} = this.props
    history.push('/admin/create')
  }

  //   public deleteRole = async (item: any) => {
  //     const {setAdminLoading} = this.props
  //     try {
  //       const {startIndex, limitNumber, searchText} = this.state

  //       await setAdminLoading(true)
  //       await deleteRoleData(item._id)

  //       Toaster({
  //         type: 'success',
  //         title: 'ADMIN',
  //         description: 'Deleted SuccessFully',
  //       })

  //       if (searchText != null && searchText !== '') {
  //         this.listAdminSearchData(startIndex, limitNumber)
  //         return
  //       }
  //       this.listAdminData(startIndex, limitNumber)
  //     } catch (e) {
  //       console.log('!!!!Error', e)
  //     } finally {
  //       await setAdminLoading(false)
  //     }
  //   }

  //   public onChangeStatus = async (item: any, checked: boolean) => {
  //     const {setAdminLoading} = this.props
  //     try {
  //       const {startIndex, limitNumber, searchText} = this.state

  //       await setAdminLoading(true)
  //       await changeStatusRoleData(item._id, checked)

  //       Toaster({
  //         type: 'success',
  //         title: 'ADMIN',
  //         description: 'Status changed successFully',
  //       })

  //       if (searchText != null && searchText !== '') {
  //         this.listAdminSearchData(startIndex, limitNumber)
  //         return
  //       }
  //       this.listAdminData(startIndex, limitNumber)
  //     } catch (e) {
  //       console.log('!!!!Error', e)
  //     } finally {
  //       await setAdminLoading(false)
  //     }
  //   }

  //   public onEditSubmit = async (values: IRoleFormValues, roleId: string) => {
  //     const {setAdminLoading} = this.props
  //     try {
  //       const {startIndex, limitNumber, searchText} = this.state

  //       await setAdminLoading(true)
  //       await editRoleData(values, roleId)

  //       Toaster({
  //         type: 'success',
  //         title: 'ADMIN',
  //         description: 'Edited SuccessFully',
  //       })

  //       if (searchText != null && searchText !== '') {
  //         this.listAdminSearchData(startIndex, limitNumber)
  //         return
  //       }
  //       this.listAdminData(startIndex, limitNumber)
  //     } catch (e) {
  //       console.log('!!!!Error', e)
  //     } finally {
  //       await setAdminLoading(false)
  //     }
  //   }

  public onEditSubmit = async (values: any) => {
    const {setAdminLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setAdminLoading(true)
      await changeStatusAdminData(values)

      Toaster({
        type: 'success',
        title: 'ADMIN',
        description: 'KYC Status Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listAdminSearchData(startIndex, limitNumber)
        return
      }
      this.listAdminData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setAdminLoading(false)
    }
  }

  public onDocumentSubmit = async (values: any, id: any) => {
    const {setAdminLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setAdminLoading(true)
      await kycDocumentUpload(values, id)

      Toaster({
        type: 'success',
        title: 'ADMIN',
        description: 'KYC Document Uploaded SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listAdminSearchData(startIndex, limitNumber)
        return
      }
      this.listAdminData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setAdminLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      adminSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      adminSelectedData: null,
    })
  }

  public showUploadModal = async (item: any) => {
    this.setState({
      uploadKycDocumentState: item,
    })
  }

  public closeUploadModal = async () => {
    this.setState({
      uploadKycDocumentState: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
      {
      title: 'Expired At',
      key: 'Expired At',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
           {item.expiredAt ? moment(item.expiredAt).isAfter(moment()) ? <div style={{ color: "green" }}>{moment(item.expiredAt).format("DD-MM-YYYY")}</div> : <div style={{ color: "red" }}>{moment(item.expiredAt).format("DD-MM-YYYY")}</div> : '-'}
          </div>
        )
      },
    },
    {
      title: 'Name',
      key: 'Name',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.name ? item.name : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.email ? item.email : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Phone',
      key: 'phone',
      render: (text: any, item: any, index: number) => {
        return <div>{item.phone ? item.phone : '-'}</div>
      },
    },
    {
      title: 'Company Name',
      key: 'Company Name',
      render: (text: any, item: any, index: number) => {
        return <div>{item.company_name ? item.company_name : '-'}</div>
      },
    },
    {
      title: 'Whatsapp',
      key: 'Whatsapp',
      render: (text: any, item: any, index: number) => {
        return <div>{item.whatsapp ? item.whatsapp : '-'}</div>
      },
    },
    {
      title: 'Stock Count',
      key: 'stock_count',
      render: (text: any, item: any, index: number) => {
        return <div>{item.stockCount ? String(item.stockCount) : '-'}</div>
      },
    },
    {
      title: 'KYC Status',
      key: 'is_kyc_verified',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{cursor: 'pointer'}}>
            {item.is_kyc_verified ? (
              <CheckOutlined style={{marginLeft: '10px', fontSize: '16px', color: 'green'}} />
            ) : (
              <CloseOutlined style={{marginLeft: '10px', fontSize: '16px', color: 'red'}} />
            )}
          </div>
        )
      },
    },
    {
      title: 'KYC Document',
      key: 'kyc_document_url',
      width: 300,
      ellipsis: true,
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{cursor: 'pointer'}}>
            {item.kyc_document_url ? (
              <a href={item.kyc_document_url} target='_blank'>
                {item.kyc_document_url}
              </a>
            ) : (
              <strong>-</strong>
            )}
          </div>
        )
      },
    },
    {
      title: 'Store Type',
      key: 'store_type',
      width: 200,
      ellipsis: true,
      render: (text: any, item: any, index: number) => {
        // return (
        //   <>{item.store_type ? <Tag color='#108ee9'>{item.store_type}</Tag> : <span>-</span>}</>
        // )
        if (item.store_type) {
          const storeTypes = item.store_type.split(','); // Split the comma-separated string
          return (
            <>
              {storeTypes.map((type: any) => (
                <Tag style={{padding:"5px",fontSize:"16px"}} color={type == "cvd" ? '#108ee9' : "#87d068"}>{type}</Tag>
              ))}
            </>
          );
        } else {
          return <span>-</span>;
        }
      },
    },
    //  {
    //   title: 'KYC Status',
    //   key: 'is_kyc_verified',
    //   width: 100,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         {item.is_kyc_verified != null ? (
    //           <Tooltip placement='bottom' title='KYC Activation'>
    //             {
    //               <Switch
    //                 checked={item.is_kyc_verified}
    //                 style={{
    //                   backgroundColor: item.is_kyc_verified ? PRIMARY_COLOR : '#bababa',
    //                 }}
    //                 onChange={(checked: boolean) => {
    //                   //call onChange Method with item
    //                   this.onChangeStatus(item, checked)
    //                 }}
    //               />
    //             }
    //           </Tooltip>
    //         ) : (
    //           '-'
    //         )}
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit Kyc Status'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Upload Kyc Document'>
                <Button
                  shape='circle'
                  icon={<UploadOutlined />}
                  onClick={() => {
                    this.showUploadModal(item)
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {adminData, adminDataTotal, adminLoading} = this.props
    const {searchText, adminSelectedData} = this.state

    return (
      <div>
        <div>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Admin'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              {/* <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateRole()
                  }}
                >
                  Add Role
                </InputButtonComponent>
              </div> */}
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  paginationConfig={{
                    total: adminDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={adminLoading}
                  dataSource={adminData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={true}
                  scrollWidth={1600}
                />
                <div>
                  <CommanModal
                    show={adminSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit KYC Status'}
                    submitText={'Save Changes'}
                    // height={300}
                  >
                    <div>
                      {adminSelectedData && (
                        <EditKYCForm
                          data={adminSelectedData}
                          onSubmit={(values: any) => {
                            this.onEditSubmit(values)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                  <CommanModal
                    show={this.state.uploadKycDocumentState ? true : false}
                    handleClose={() => {
                      this.closeUploadModal()
                    }}
                    title={'Upload KYC Document'}
                    submitText={'Save Changes'}
                    // height={300}
                    applyZindex={true}
                  >
                    <div>
                      {this.state.uploadKycDocumentState && (
                        <UploadKycDocumentForm
                          data={this.state.uploadKycDocumentState}
                          onSubmit={(values: any, id: any) => {
                            this.onDocumentSubmit(values, id)
                          }}
                          handleCancel={() => {
                            this.closeUploadModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  adminData: state.admin.adminData,
  adminDataTotal: state.admin.adminDataTotal,
  adminLoading: state.admin.adminLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListAdminData: (adminData: any) => {
      dispatch(admin.actions.setAdminData(adminData))
    },
    setListAdminDataTotal: (adminDataTotal: number) => {
      dispatch(admin.actions.setAdminDataTotal(adminDataTotal))
    },
    setAdminLoading: (adminLoading: boolean) => {
      dispatch(admin.actions.setAdminLoading(adminLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListRoleData))
