/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as buyRequest from '../redux/BuyRequestRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons'
import {
  changeStatusBuyRequestData,
  deleteBuyRequestData,
  editBuyRequestData,
  getBuyRequestData,
  getSearchBuyRequestData,
} from '../redux/BuyRequestCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {CommanModal} from '../../../modules/comman/components/Modal'
import {EditBuyRequestForm} from './EditBuyRequestForm'
import {IBuyRequestFormValues} from './CreateBuyRequestForm'
import {Tag} from 'antd'
import {returnStockId} from '../../../comman/helper'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  buyRequestData: any
  buyRequestDataTotal: number
  buyRequestLoading: boolean
  setListBuyRequestData: (buyRequestData: any) => void
  setListBuyRequestDataTotal: (buyRequestDataTotal: number) => void
  setBuyRequestLoading: (buyRequestLoading: boolean) => void
  history?: History<LocationState>
  loginUser: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  buyRequestSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListBuyRequestData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      buyRequestSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListBuyRequestData(pagination)
    )
  }

  public getListBuyRequestData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listBuyRequestSearchData(start, end)
        } else {
          this.listBuyRequestData(start, end)
        }
      }
    )
  }

  public listBuyRequestData = async (start: number, end: any) => {
    const {setBuyRequestLoading} = this.props
    try {
      const {setListBuyRequestData, setListBuyRequestDataTotal, loginUser} = this.props
      await setBuyRequestLoading(true)
      let status = ''
      if (loginUser?.role === 'admin') {
        status = 'PENDING'
      }
      let buyRequestData = await getBuyRequestData(start, end, status)
      if (buyRequestData.data) {
        await setListBuyRequestData(buyRequestData.data.docs)
        await setListBuyRequestDataTotal(buyRequestData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setBuyRequestLoading(false)
    }
  }

  public listBuyRequestSearchData = async (start: number, end: any) => {
    const {setBuyRequestLoading} = this.props
    try {
      const {setListBuyRequestData, setListBuyRequestDataTotal} = this.props
      const {searchText} = this.state

      await setBuyRequestLoading(true)
      let buyRequestData = await getSearchBuyRequestData(start, end, searchText)
      if (buyRequestData.data) {
        await setListBuyRequestData(buyRequestData.data.docs)
        await setListBuyRequestDataTotal(buyRequestData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setBuyRequestLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listBuyRequestSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateBuyRequest = () => {
    const {history} = this.props
    history.push('/buyRequest/create')
  }

  public deleteBuyRequest = async (item: any) => {
    const {setBuyRequestLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setBuyRequestLoading(true)
      await deleteBuyRequestData(item._id)

      Toaster({
        type: 'success',
        title: 'BUY REQUEST',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listBuyRequestSearchData(startIndex, limitNumber)
        return
      }
      this.listBuyRequestData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setBuyRequestLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setBuyRequestLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setBuyRequestLoading(true)
      await changeStatusBuyRequestData(item._id, checked)

      Toaster({
        type: 'success',
        title: 'BUY REQUEST',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listBuyRequestSearchData(startIndex, limitNumber)
        return
      }
      this.listBuyRequestData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setBuyRequestLoading(false)
    }
  }

  public onEditSubmit = async (values: IBuyRequestFormValues, buyRequestId: string) => {
    const {setBuyRequestLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setBuyRequestLoading(true)
      await editBuyRequestData(values, buyRequestId)

      Toaster({
        type: 'success',
        title: 'BUY REQUEST',
        description: 'Updated SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listBuyRequestSearchData(startIndex, limitNumber)
        return
      }
      this.listBuyRequestData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setBuyRequestLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      buyRequestSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      buyRequestSelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public clientColumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.status ? (
              <Tag
                color={
                  config.statusTagColor[item.status]
                    ? config.statusTagColor[item.status]
                    : 'default'
                }
              >
                {item.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Stock Ids',
      key: 'stock_ids',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item?.stock_diamonds
              ? item?.stock_diamonds
                  .map((stockItem: any) => returnStockId(stockItem.stock_id.stock_id))
                  .join(', ')
              : '-'}
          </div>
        )
      },
    },
    {
      title: 'Quantity',
      key: 'stock_diamonds',
      render: (text: any, item: any, index: number) => {
        return <div> {item.stock_diamonds ? item.stock_diamonds.length : '-'}</div>
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              {item.status === 'PENDING' ? (
                <Tooltip placement='bottom' title='Preview'>
                  <Button
                    shape='circle'
                    icon={<EyeOutlined />}
                    onClick={() => {
                      this.showEditModal(item)
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip placement='bottom' title='Edit'>
                  <Button
                    shape='circle'
                    icon={<EditOutlined />}
                    onClick={() => {
                      this.showEditModal(item)
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        )
      },
    },
  ]

  public adminColumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Full Name',
      key: 'Full Name',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item?.client_id?.first_name && item?.client_id?.last_name
                ? `${item.client_id.first_name} ${item.client_id.last_name}`
                : item.client_id.first_name
                ? item.client_id.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      render: (text: any, item: any, index: number) => {
        return <div>{item?.client_id?.email ? item.client_id.email : '-'}</div>
      },
    },
    {
      title: 'Stock Ids',
      key: 'stock_ids',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item?.stock_diamonds
              ? item?.stock_diamonds
                  .map((stockItem: any) => returnStockId(stockItem.stock_id.stock_id))
                  .join(', ')
              : '-'}
          </div>
        )
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.status ? (
              <Tag
                color={
                  config.statusTagColor[item.status]
                    ? config.statusTagColor[item.status]
                    : 'default'
                }
              >
                {item.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Quantity',
      key: 'stock_diamonds',
      render: (text: any, item: any, index: number) => {
        return <div> {item.stock_diamonds ? item.stock_diamonds.length : '-'}</div>
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {buyRequestData, buyRequestDataTotal, buyRequestLoading, loginUser} = this.props
    const {buyRequestSelectedData} = this.state

    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div style={{overflowX: 'auto'}}>
              <ListTable
                rowKey={(record: {_id: any}) => record._id}
                onChange={this.handleTableChange}
                onPaginationChange={(e: any) => {
                  if (e - 1) {
                    this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                    return
                  }

                  this.counter = 0
                  this.tempCounter = 0
                }}
                column={loginUser?.role === 'admin' ? this.adminColumns : this.clientColumns}
                paginationConfig={{
                  total: buyRequestDataTotal,
                  showSizeChanger: true,
                  pageSize: this.state.pagination.pageSize,
                  current: this.state.pagination.current,
                }}
                isLoading={buyRequestLoading}
                dataSource={buyRequestData}
                counter={this.counter}
                tempCounter={this.tempCounter}
                isScroll={true}
                scrollWidth={1200}
              />
              <div>
                <CommanModal
                  show={buyRequestSelectedData ? true : false}
                  handleClose={() => {
                    this.closeEditModal()
                  }}
                  modalSize={'modal-xl'}
                  title={
                    loginUser?.role === 'admin' ||
                    (loginUser?.role === 'client' &&
                      buyRequestSelectedData &&
                      buyRequestSelectedData.status !== 'PENDING')
                      ? 'Edit Buy Request'
                      : 'View Buy Request'
                  }
                  submitText={'Save Changes'}
                >
                  <div>
                    {buyRequestSelectedData && (
                      <EditBuyRequestForm
                        data={buyRequestSelectedData}
                        loginUserData={loginUser}
                        onSubmit={(values: IBuyRequestFormValues, buyRequestId: string) => {
                          this.onEditSubmit(values, buyRequestId)
                        }}
                        handleCancel={() => {
                          this.closeEditModal()
                        }}
                      />
                    )}
                  </div>
                </CommanModal>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  buyRequestData: state.buyRequest.buyRequestData,
  buyRequestDataTotal: state.buyRequest.buyRequestDataTotal,
  buyRequestLoading: state.buyRequest.buyRequestLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListBuyRequestData: (buyRequestData: any) => {
      dispatch(buyRequest.actions.setBuyRequestData(buyRequestData))
    },
    setListBuyRequestDataTotal: (buyRequestDataTotal: number) => {
      dispatch(buyRequest.actions.setBuyRequestDataTotal(buyRequestDataTotal))
    },
    setBuyRequestLoading: (buyRequestLoading: boolean) => {
      dispatch(buyRequest.actions.setBuyRequestLoading(buyRequestLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListBuyRequestData))
