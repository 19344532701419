import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTSVG} from '../../../helpers'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  canAccess?: Array<string>
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  canAccess,
}) => {
  const {pathname} = useLocation()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      {canAccess?.includes(user.role || '') && (
        <div className='menu-item me-lg-1'>
          <Link
            className={clsx('menu-link py-3', {
              active: checkIsActive(pathname, to),
            })}
            to={to}
          >
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}

            {icon && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}

            {fontIcon && (
              <span className='menu-icon'>
                <i className={clsx('bi fs-3', fontIcon)}></i>
              </span>
            )}

            <span className='menu-title'>{title}</span>

            {hasArrow && <span className='menu-arrow'></span>}
          </Link>
        </div>
      )}
    </>
  )
}

export {MenuItem}
