import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {ApiKeyModal} from '../models/ApiKeyModal'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setApiKeyData: 'SET_API_KEY_DATA',
  setApiKeyDataTotal: 'SET_API_KEY_DATA_TOTAL',
  setApiKeyLoading: 'SET_API_KEY_LOADER'
}

const initialClientState: IApiKeyState = {
  apiKeyData: undefined,
  apiKeyDataTotal: undefined,
  apiKeyLoading: false
}

export interface IApiKeyState {
  apiKeyData?: ApiKeyModal
  apiKeyDataTotal?: number
  apiKeyLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'apiKey', whitelist: ['apiKeyData', 'apiKeyDataTotal']},
    (state: IApiKeyState = initialClientState, action: ActionWithPayload<IApiKeyState>) => {
    switch (action.type) {
      case actionTypes.setApiKeyData: {
        const apiKeyData = action.payload?.apiKeyData
        return {...state, apiKeyData}
      }
            
      case actionTypes.setApiKeyDataTotal: {
        const apiKeyDataTotal = action.payload?.apiKeyDataTotal
        return {...state, apiKeyDataTotal}
      }
        
      case actionTypes.setApiKeyLoading: {
        const apiKeyLoading = action.payload?.apiKeyLoading
        return {...state, apiKeyLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setApiKeyData: (apiKeyData: Array<ApiKeyModal>) => ({type: actionTypes.setApiKeyData, payload: {apiKeyData : apiKeyData}}),
  setApiKeyDataTotal: (apiKeyDataTotal: number) => ({ type: actionTypes.setApiKeyDataTotal, payload: { apiKeyDataTotal } }),
  setApiKeyLoading: (apiKeyLoading: boolean) => ({type: actionTypes.setApiKeyLoading, payload: {apiKeyLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setApiKeyData, function* setApiKeyData() {
    yield put(actions.setApiKeyLoading(false))
  })
}
