import React from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

export function TermsAndConditionsStaticContent() {
  return (
    <>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        }}
      >
        {/* begin::Content */}
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          {/* begin::Logo */}
          <Link to='/auth/login' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/login_logo.png')} className='h-75px' />
          </Link>
          {/* end::Logo */}
          {/* begin::Wrapper */}
          <div className='w-75 bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <div className='page'>
              <div id='terms-and-conditions'>
                <h1 className='text-center mb-10 text-dark'>{'Terms & Conditions'}</h1>
                <ol>
                  <li>
                    <b>{'INTELLECTUAL PROPERTY, LICENCE AND RESTRICTIONS'}</b>
                    <ol>
                      <li>
                        {
                          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae elementum ipsum. Nam eget congue libero. Donec at elit eget ante pulvinar dictum ac in lorem. Sed sed molestie mi, sit amet convallis erat. Aliquam eu sagittis nulla. Nulla id mollis dolor. Pellentesque sagittis odio a blandit ultricies.'
                        }
                      </li>
                      <li>
                        {
                          'Subject to your compliance with these Terms, the Developer grants you a limited, non-exclusive, revocable, non-transferrable licence to:'
                        }
                        <ol>
                          <li>
                            {
                              'lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae elementum ipsum. Nam eget congue libero. Donec at elit eget ante pulvinar dictum ac in lorem. Sed sed molestie mi, sit amet convallis erat. Aliquam eu sagittis nulla. Nulla id mollis dolor. Pellentesque sagittis odio a blandit ultricies.'
                            }
                          </li>
                          <li>
                            {
                              'lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae elementum ipsum. Nam eget congue libero. Donec at elit eget ante pulvinar dictum ac in lorem. Sed sed molestie mi, sit amet convallis erat. Aliquam eu sagittis nulla. Nulla id mollis dolor. Pellentesque sagittis odio a blandit ultricies.'
                            }
                          </li>
                        </ol>
                      </li>
                      <li>
                        {'Any rights not expressly granted herein are reserved by the Developer.'}
                      </li>
                      <li>{'You may not:'}</li>
                      <ol>
                        <li>
                          {
                            'etiam quis purus eget tortor efficitur tempor. Sed volutpat, dolor in porta aliquam, quam enim accumsan dui.'
                          }
                        </li>
                        <li>
                          {
                            'etiam quis purus eget tortor efficitur tempor. Sed volutpat, dolor in porta aliquam, quam enim accumsan dui.'
                          }
                        </li>
                        <li>
                          {
                            'etiam quis purus eget tortor efficitur tempor. Sed volutpat, dolor in porta aliquam, quam enim accumsan dui.'
                          }
                        </li>
                        <li>
                          {
                            'etiam quis purus eget tortor efficitur tempor. Sed volutpat, dolor in porta aliquam, quam enim accumsan dui.'
                          }
                        </li>
                        <li>
                          {
                            'etiam quis purus eget tortor efficitur tempor. Sed volutpat, dolor in porta aliquam, quam enim accumsan dui.'
                          }
                        </li>
                        <li>
                          {
                            'praesent sagittis pharetra justo vehicula tincidunt. Cras ut augue non massa gravida porttitor at et dolor. Ut dolor urna, fringilla eu auctor vel, tristique et turpis. Vestibulum nec massa ac nisi dignissim egestas. Vestibulum non malesuada urna. In hac habitasse platea dictumst. Vestibulum vel leo mattis, efficitur lorem et, rutrum velit. Cras venenatis semper diam, non tempor eros luctus ut. Aenean id diam orci. Praesent a nisl vehicula, aliquam odio id, mattis velit.'
                          }
                        </li>
                      </ol>
                    </ol>
                  </li>

                  <ol>
                    <li>
                      <b>{'INTELLECTUAL PROPERTY, LICENCE AND RESTRICTIONS'}</b>
                      <ol>
                        <li>
                          {
                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae elementum ipsum. Nam eget congue libero. Donec at elit eget ante pulvinar dictum ac in lorem. Sed sed molestie mi, sit amet convallis erat. Aliquam eu sagittis nulla. Nulla id mollis dolor. Pellentesque sagittis odio a blandit ultricies. '
                          }
                        </li>
                        <li>
                          {
                            'Subject to your compliance with these Terms, the Developer grants you a limited, non-exclusive, revocable, non-transferrable licence to:'
                          }
                          <ol>
                            <li>
                              {
                                'lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae elementum ipsum. Nam eget congue libero. Donec at elit eget ante pulvinar dictum ac in lorem. Sed sed molestie mi, sit amet convallis erat. Aliquam eu sagittis nulla. Nulla id mollis dolor. Pellentesque sagittis odio a blandit ultricies.'
                              }
                            </li>
                            <li>
                              {
                                'lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae elementum ipsum. Nam eget congue libero. Donec at elit eget ante pulvinar dictum ac in lorem. Sed sed molestie mi, sit amet convallis erat. Aliquam eu sagittis nulla. Nulla id mollis dolor. Pellentesque sagittis odio a blandit ultricies.'
                              }
                            </li>
                          </ol>
                        </li>
                        <li>
                          {'Any rights not expressly granted herein are reserved by the Developer.'}
                        </li>
                        <li>{'You may not:'}</li>
                        <ol>
                          <li>
                            {
                              'etiam quis purus eget tortor efficitur tempor. Sed volutpat, dolor in porta aliquam, quam enim accumsan dui.'
                            }
                          </li>
                          <li>
                            {
                              'etiam quis purus eget tortor efficitur tempor. Sed volutpat, dolor in porta aliquam, quam enim accumsan dui.'
                            }
                          </li>
                          <li>
                            {
                              'etiam quis purus eget tortor efficitur tempor. Sed volutpat, dolor in porta aliquam, quam enim accumsan dui.'
                            }
                          </li>
                          <li>
                            {
                              'etiam quis purus eget tortor efficitur tempor. Sed volutpat, dolor in porta aliquam, quam enim accumsan dui.'
                            }
                          </li>
                          <li>
                            {
                              'etiam quis purus eget tortor efficitur tempor. Sed volutpat, dolor in porta aliquam, quam enim accumsan dui.'
                            }
                          </li>
                          <li>
                            {
                              'praesent sagittis pharetra justo vehicula tincidunt. Cras ut augue non massa gravida porttitor at et dolor. Ut dolor urna, fringilla eu auctor vel, tristique et turpis. Vestibulum nec massa ac nisi dignissim egestas. Vestibulum non malesuada urna. In hac habitasse platea dictumst. Vestibulum vel leo mattis, efficitur lorem et, rutrum velit. Cras venenatis semper diam, non tempor eros luctus ut. Aenean id diam orci. Praesent a nisl vehicula, aliquam odio id, mattis velit.'
                            }
                          </li>
                        </ol>
                      </ol>
                    </li>
                  </ol>
                </ol>
              </div>
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
      </div>
    </>
  )
}
