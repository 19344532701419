import React from 'react'
import axios from 'axios'
import {OrderModal} from '../models/OrderModal'
import {IOrderFormValues} from '../containers/CreateOrderForm'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_ADMIN_ORDER_URL = `${API_URL}v1/auth/admin-order/`
export const GET_ORDER_URL = `${API_URL}v1/auth/order/`
export const SEARCH_ORDER_URL = `${API_URL}v1/auth/filter-order/`
export const CREATE_ORDER_URL = `${API_URL}v1/auth/order`
export const EDIT_ORDER_URL = `${API_URL}v1/auth/order/`
export const DELETE_ORDER_URL = `${API_URL}v1/auth/order/`
export const CHANGE_STATUS_ORDER_URL = `${API_URL}v1/auth/order/active-status/`

// Get Admin Order Data
export async function getAdminOrderData(startIndex: number, limitNumber: number) {
  try {
    let orderData = await axios.get(`${GET_ADMIN_ORDER_URL}${startIndex}/${limitNumber}`)
    return orderData
  } catch (e) {
    throw e
  }
}

// Get Order Data
export async function getOrderData(startIndex: number, limitNumber: number) {
  try {
    let orderData = await axios.get(`${GET_ORDER_URL}${startIndex}/${limitNumber}`)
    return orderData
  } catch (e) {
    throw e
  }
}

// Search Order Data
export async function getSearchOrderData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let orderData = await axios.post(`${SEARCH_ORDER_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return orderData
  } catch (e) {
    throw e
  }
}

// Create Order Data
export async function createOrderData(orderObject: IOrderFormValues) {
  try {
    let createOrderData = await axios.post(`${CREATE_ORDER_URL}`, {
      ...orderObject,
    })
    return createOrderData
  } catch (e) {
    throw e
  }
}

// Delete Order Data
export async function deleteOrderData(orderId: string) {
  try {
    let deleteOrderData = await axios.delete(`${DELETE_ORDER_URL}${orderId}`)
    return deleteOrderData
  } catch (e) {
    throw e
  }
}

// Change Status Order Data
export async function changeStatusOrderData(orderId: string, flag: boolean) {
  try {
    let orderData = await axios.put(`${CHANGE_STATUS_ORDER_URL}`, {
      orderId,
      flag,
    })
    return orderData
  } catch (e) {
    throw e
  }
}

// Edit Order Data
export async function editOrderData(orderObject: IOrderFormValues, orderId: string) {
  try {
    let editOrderData = await axios.put(`${EDIT_ORDER_URL}`, {
      ...orderObject,
      orderId,
    })
    return editOrderData
  } catch (e) {
    throw e
  }
}
