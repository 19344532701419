import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_ALL_ADMIN_KPIS_URL = `${API_URL}v1/auth/admin-stats/`
export const GET_ALL_CLIENT_KPIS_URL = `${API_URL}v1/auth/stats/`

// Get all client KPIs Data
export async function getAllClientKPIsData() {
  try {
    let allKpisData = await axios.get(`${GET_ALL_CLIENT_KPIS_URL}`)

    return allKpisData
  } catch (e) {
    throw e
  }
}

// Get all Admin KPIs Data
export async function getAllAdminKPIsData() {
  try {
    let allKpisData = await axios.get(`${GET_ALL_ADMIN_KPIS_URL}`)

    return allKpisData
  } catch (e) {
    throw e
  }
}
