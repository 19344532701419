/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createShipmentPriceData, getShipmentPriceData} from '../redux/ShipmentPriceCRUD'
import * as shipmentPrice from '../redux/ShipmentPriceRedux'
import {CreateShipmentPriceForm} from './CreateShipmentPriceForm'
import {IShipmentPriceFormValues} from './CreateShipmentPriceForm'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callShipmentPriceData = async () => {
    try {
      let shipmentPriceData = await getShipmentPriceData(0, 10)
      if (shipmentPriceData.data) {
        await dispatch(shipmentPrice.actions.setShipmentPriceLoading(true))
        // once shipmentPrice data set then loading will automatically off using saga middleware
        await dispatch(shipmentPrice.actions.setShipmentPriceData(shipmentPriceData.data.docs))
        await dispatch(
          shipmentPrice.actions.setShipmentPriceDataTotal(shipmentPriceData.data.total)
        )
        history.push('/shipmentPrice/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IShipmentPriceFormValues) => {
    try {
      await createShipmentPriceData(values)
      await callShipmentPriceData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <CreateShipmentPriceForm onSubmit={(values: IShipmentPriceFormValues) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Create',
            path: '/shipmentPrice/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'Create',
            path: '/shipmentPrice/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.SHIPMENTPRICE'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
