import React from 'react'
import axios from 'axios'
import {RoleModal} from '../models/RoleModal'
import {IRoleFormValues} from '../containers/CreateRoleForm'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_ROLE_URL = `${API_URL}v1/auth/role/`
export const GET_UNAUTH_ROLE_URL = `${API_URL}v1/role/`
export const SEARCH_ROLE_URL = `${API_URL}v1/auth/filter-role/`
export const CREATE_ROLE_URL = `${API_URL}v1/auth/role/`
export const EDIT_ROLE_URL = `${API_URL}v1/auth/role/`
export const DELETE_ROLE_URL = `${API_URL}v1/auth/role/`
export const CHANGE_STATUS_ROLE_URL = `${API_URL}v1/auth/role/active-status/`

// Get Role Data
export async function getRoleData(startIndex: number, limitNumber: number) {
  try {
    let roleData = await axios.get(`${GET_ROLE_URL}${startIndex}/${limitNumber}`)
    return roleData
  } catch (e) {
    throw e
  }
}

// Get All Role Data for DropDown
export async function getRoleDataForDropDown() {
  try {
    let roleData = await axios.get(`${GET_ROLE_URL}`)
    return roleData
  } catch (e) {
    throw e
  }
}

// Get All Role Data for DropDown from unauth call
export async function getRoleDataUnAuthForDropDown() {
  try {
    let roleData = await axios.get(`${GET_UNAUTH_ROLE_URL}`)
    return roleData
  } catch (e) {
    throw e
  }
}

// Search Role Data
export async function getSearchRoleData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let roleData = await axios.post(`${SEARCH_ROLE_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return roleData
  } catch (e) {
    throw e
  }
}

// Create Role Data
export async function createRoleData(roleObject: IRoleFormValues) {
  try {
    let createRoleData = await axios.post(`${CREATE_ROLE_URL}`, {
      ...roleObject,
    })
    return createRoleData
  } catch (e) {
    throw e
  }
}

// Delete Role Data
export async function deleteRoleData(roleId: string) {
  try {
    let deleteRoleData = await axios.delete(`${DELETE_ROLE_URL}${roleId}`)
    return deleteRoleData
  } catch (e) {
    throw e
  }
}

// Change Status Role Data
export async function changeStatusRoleData(roleId: string, flag: boolean) {
  try {
    let roleData = await axios.put(`${CHANGE_STATUS_ROLE_URL}`, {
      roleId,
      flag,
    })
    return roleData
  } catch (e) {
    throw e
  }
}

// Edit Role Data
export async function editRoleData(roleObject: IRoleFormValues, roleId: string) {
  try {
    let editRoleData = await axios.put(`${EDIT_ROLE_URL}`, {
      ...roleObject,
      roleId,
    })
    return editRoleData
  } catch (e) {
    throw e
  }
}
