/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as role from '../redux/RoleRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {
  changeStatusRoleData,
  deleteRoleData,
  editRoleData,
  getRoleData,
  getSearchRoleData,
} from '../redux/RoleCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {CommanModal} from '../../../modules/comman/components/Modal'
import {EditRoleForm} from './EditRoleForm'
import {IRoleFormValues} from './CreateRoleForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor

interface IRecordsTableProps {
  roleData: any
  roleDataTotal: number
  roleLoading: boolean
  setListRoleData: (roleData: any) => void
  setListRoleDataTotal: (roleDataTotal: number) => void
  setRoleLoading: (roleLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  roleSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListRoleData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      roleSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListRoleData(pagination)
    )
  }

  public getListRoleData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listRoleSearchData(start, end)
        } else {
          this.listRoleData(start, end)
        }
      }
    )
  }

  public listRoleData = async (start: number, end: any) => {
    const {setRoleLoading} = this.props
    try {
      const {setListRoleData, setListRoleDataTotal} = this.props
      await setRoleLoading(true)
      let roleData = await getRoleData(start, end)
      if (roleData.data) {
        await setListRoleData(roleData.data.docs)
        await setListRoleDataTotal(roleData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setRoleLoading(false)
    }
  }

  public listRoleSearchData = async (start: number, end: any) => {
    const {setRoleLoading} = this.props
    try {
      const {setListRoleData, setListRoleDataTotal} = this.props
      const {searchText} = this.state

      await setRoleLoading(true)
      let roleData = await getSearchRoleData(start, end, searchText)
      if (roleData.data) {
        await setListRoleData(roleData.data.docs)
        await setListRoleDataTotal(roleData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setRoleLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listRoleSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateRole = () => {
    const {history} = this.props
    history.push('/role/create')
  }

  public deleteRole = async (item: any) => {
    const {setRoleLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setRoleLoading(true)
      await deleteRoleData(item._id)

      Toaster({
        type: 'success',
        title: 'ROLE',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listRoleSearchData(startIndex, limitNumber)
        return
      }
      this.listRoleData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setRoleLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setRoleLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setRoleLoading(true)
      await changeStatusRoleData(item._id, checked)

      Toaster({
        type: 'success',
        title: 'ROLE',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listRoleSearchData(startIndex, limitNumber)
        return
      }
      this.listRoleData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setRoleLoading(false)
    }
  }

  public onEditSubmit = async (values: IRoleFormValues, roleId: string) => {
    const {setRoleLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setRoleLoading(true)
      await editRoleData(values, roleId)

      Toaster({
        type: 'success',
        title: 'ROLE',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listRoleSearchData(startIndex, limitNumber)
        return
      }
      this.listRoleData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setRoleLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      roleSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      roleSelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Name',
      key: 'Name',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.name ? item.name : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Rap Price Discount',
      key: 'rap_price_discount',
      width: 250,
      render: (text: any, item: any, index: number) => {
        return <div>{item.rap_price_discount ? String(item.rap_price_discount) : '-'}</div>
      },
    },
    {
      title: 'isActive',
      key: 'isActive',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement='bottom' title='Role Activation'>
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
                    }}
                    onChange={(checked: boolean) => {
                      //call onChange Method with item
                      this.onChangeStatus(item, checked)
                    }}
                  />
                }
              </Tooltip>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete client api
                    this.deleteRole(item)
                  }}
                  title='Are you sure you want to delete role?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {roleData, roleDataTotal, roleLoading} = this.props
    const {searchText, roleSelectedData} = this.state

    return (
      <div>
        <div>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Role'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateRole()
                  }}
                >
                  Add Role
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  paginationConfig={{
                    total: roleDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={roleLoading}
                  dataSource={roleData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={false}
                />
                <div>
                  <CommanModal
                    show={roleSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Role'}
                    submitText={'Save Changes'}
                    // height={300}
                  >
                    <div>
                      {roleSelectedData && (
                        <EditRoleForm
                          data={roleSelectedData}
                          onSubmit={(values: IRoleFormValues, roleId: string) => {
                            this.onEditSubmit(values, roleId)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  roleData: state.role.roleData,
  roleDataTotal: state.role.roleDataTotal,
  roleLoading: state.role.roleLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListRoleData: (roleData: any) => {
      dispatch(role.actions.setRoleData(roleData))
    },
    setListRoleDataTotal: (roleDataTotal: number) => {
      dispatch(role.actions.setRoleDataTotal(roleDataTotal))
    },
    setRoleLoading: (roleLoading: boolean) => {
      dispatch(role.actions.setRoleLoading(roleLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListRoleData))
