/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as apiKey from '../redux/ApiKeyRedux'
import {Tooltip, Popconfirm, Input, Typography} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {
  changeStatusApiKeyData,
  deleteApiKeyData,
  editApiKeyData,
  getApiKeyData,
  getSearchApiKeyData,
} from '../redux/ApiKeyCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {CommanModal} from '../../../modules/comman/components/Modal'
import {EditApiKeyForm} from './EditApiKeyForm'
import {IApiKeyFormValues} from './CreateApiKeyForm'

const {Paragraph} = Typography
const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor

interface IRecordsTableProps {
  apiKeyData: any
  apiKeyDataTotal: number
  apiKeyLoading: boolean
  setListApiKeyData: (apiKeyData: any) => void
  setListApiKeyDataTotal: (apiKeyDataTotal: number) => void
  setApiKeyLoading: (apiKeyLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  apiKeySelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListApiKeyData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      apiKeySelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListApiKeyData(pagination)
    )
  }

  public getListApiKeyData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listApiKeySearchData(start, end)
        } else {
          this.listApiKeyData(start, end)
        }
      }
    )
  }

  public listApiKeyData = async (start: number, end: any) => {
    const {setApiKeyLoading} = this.props
    try {
      const {setListApiKeyData, setListApiKeyDataTotal} = this.props
      await setApiKeyLoading(true)
      let apiKeyData = await getApiKeyData(start, end)
      if (apiKeyData.data) {
        await setListApiKeyData(apiKeyData.data.docs)
        await setListApiKeyDataTotal(apiKeyData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setApiKeyLoading(false)
    }
  }

  public listApiKeySearchData = async (start: number, end: any) => {
    const {setApiKeyLoading} = this.props
    try {
      const {setListApiKeyData, setListApiKeyDataTotal} = this.props
      const {searchText} = this.state
      await setApiKeyLoading(true)

      let apiKeyData = await getSearchApiKeyData(start, end, searchText)
      if (apiKeyData.data) {
        await setListApiKeyData(apiKeyData.data.docs)
        await setListApiKeyDataTotal(apiKeyData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setApiKeyLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listApiKeySearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateApiKey = () => {
    const {history} = this.props
    history.push('/apiKey/create')
  }

  public deleteApiKey = async (item: any) => {
    const {setApiKeyLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setApiKeyLoading(true)
      await deleteApiKeyData(item._id)

      Toaster({
        type: 'success',
        title: 'APIKEY',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listApiKeySearchData(startIndex, limitNumber)
        return
      }
      this.listApiKeyData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setApiKeyLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setApiKeyLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setApiKeyLoading(true)
      await changeStatusApiKeyData(item._id, checked)

      Toaster({
        type: 'success',
        title: 'APIKEY',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listApiKeySearchData(startIndex, limitNumber)
        return
      }
      this.listApiKeyData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setApiKeyLoading(false)
    }
  }

  public onEditSubmit = async (values: IApiKeyFormValues, apiKeyId: string) => {
    const {setApiKeyLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setApiKeyLoading(true)
      await editApiKeyData(values, apiKeyId)

      Toaster({
        type: 'success',
        title: 'APIKEY',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listApiKeySearchData(startIndex, limitNumber)
        return
      }
      this.listApiKeyData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setApiKeyLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      apiKeySelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      apiKeySelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Admn Name',
      key: 'name',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.admin_id ? (item.admin_id.name ? item.admin_id.name : '-') : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Name',
      key: 'Name',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.name ? item.name : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Key',
      key: 'key',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.key ? <Paragraph copyable>{item.key}</Paragraph> : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.email ? item.email : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Used Count',
      key: 'Used Count',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.used_count ? String(item.used_count) : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'isActive',
      key: 'isActive',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement='bottom' title='API KEY Activation'>
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
                    }}
                    onChange={(checked: boolean) => {
                      //call onChange Method with item
                      this.onChangeStatus(item, checked)
                    }}
                  />
                }
              </Tooltip>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete client api
                    this.deleteApiKey(item)
                  }}
                  title='Are you sure you want to delete API Key?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {apiKeyData, apiKeyDataTotal, apiKeyLoading} = this.props
    const {searchText, apiKeySelectedData} = this.state

    return (
      <div>
        <div>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Api Key'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateApiKey()
                  }}
                >
                  Add Api Key
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  column={this.columns}
                  paginationConfig={{
                    total: apiKeyDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={apiKeyLoading}
                  dataSource={apiKeyData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={false}
                />
                <div>
                  <CommanModal
                    show={apiKeySelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Api Key'}
                    submitText={'Save Changes'}
                    // height={300}
                  >
                    <div>
                      {apiKeySelectedData && (
                        <EditApiKeyForm
                          data={apiKeySelectedData}
                          onSubmit={(values: IApiKeyFormValues, apiKeyId: string) => {
                            this.onEditSubmit(values, apiKeyId)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  apiKeyData: state.apiKey.apiKeyData,
  apiKeyDataTotal: state.apiKey.apiKeyDataTotal,
  apiKeyLoading: state.apiKey.apiKeyLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListApiKeyData: (apiKeyData: any) => {
      dispatch(apiKey.actions.setApiKeyData(apiKeyData))
    },
    setListApiKeyDataTotal: (apiKeyDataTotal: number) => {
      dispatch(apiKey.actions.setApiKeyDataTotal(apiKeyDataTotal))
    },
    setApiKeyLoading: (apiKeyLoading: boolean) => {
      dispatch(apiKey.actions.setApiKeyLoading(apiKeyLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListApiKeyData))
