/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ListWraaper} from './containers/List'
import {CreateWrapper} from './containers/CreateStaticPage'

export function StaticPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/staticpage/list' exact={true} component={ListWraaper} />
        <Route path='/staticpage/create' exact={true} component={CreateWrapper} />
        <Redirect from='/staticpage' exact={true} to='/staticpage/list' />
        <Redirect to='/staticpage/list' />
      </Switch>
    </div>
  )
}
