import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setStaticPageData: 'SET_STATIC_PAGE_DATA',
  setStaticPageDataTotal: 'SET_STATIC_PAGE_DATA_TOTAL',
  setStaticPageLoading: 'SET_STATIC_PAGE_LOADER',
}

const initialOrderState: IOrderState = {
  staticPageData: undefined,
  staticPageDataTotal: undefined,
  staticPageLoading: false,
}

export interface IOrderState {
  staticPageData?: any
  staticPageDataTotal?: number
  staticPageLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'staticpage', whitelist: ['staticPageData', 'staticPageDataTotal']},
  (state: IOrderState = initialOrderState, action: ActionWithPayload<IOrderState>) => {
    switch (action.type) {
      case actionTypes.setStaticPageData: {
        const staticPageData = action.payload?.staticPageData
        return {...state, staticPageData}
      }

      case actionTypes.setStaticPageDataTotal: {
        const staticPageDataTotal = action.payload?.staticPageDataTotal
        return {...state, staticPageDataTotal}
      }

      case actionTypes.setStaticPageLoading: {
        const staticPageLoading = action.payload?.staticPageLoading
        return {...state, staticPageLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setStaticPageData: (staticPageData: Array<any>) => ({
    type: actionTypes.setStaticPageData,
    payload: {staticPageData: staticPageData},
  }),
  setStaticPageDataTotal: (staticPageDataTotal: number) => ({
    type: actionTypes.setStaticPageDataTotal,
    payload: {staticPageDataTotal},
  }),
  setStaticPageLoading: (staticPageLoading: boolean) => ({
    type: actionTypes.setStaticPageLoading,
    payload: {staticPageLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setStaticPageData, function* loginSaga() {
    yield put(actions.setStaticPageLoading(false))
  })
}
