/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getAdminOrderData, getOrderData} from '../redux/OrderCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as order from '../redux/OrderRedux'
import ListOrderData from './ListOrderData'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {RootState} from '../../../../setup'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const callOrderData = async () => {
    try {
      let orderData
      if (user.role === 'admin') {
        orderData = await getAdminOrderData(0, 10)
      } else {
        orderData = await getOrderData(0, 10)
      }
      if (orderData.data) {
        await dispatch(order.actions.setOrderLoading(true))
        // once order data set then loading will automatically off using saga middleware
        await dispatch(order.actions.setOrderData(orderData.data.docs))
        await dispatch(order.actions.setOrderDataTotal(orderData.data.total))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callOrderData()
  }, [])

  return (
    <div>
      <ListOrderData />
    </div>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/order/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/order/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.ORDER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
