import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as client from '../../app/component/client'
import * as dashboard from '../../app/component/dashboard'
import * as role from '../../app/component/role'
import * as apiKey from '../../app/component/apiKey'
import * as shipmentPrice from '../../app/component/shipmentPrice'
import * as buyRequest from '../../app/component/buyRequest'
import * as order from '../../app/component/order'
import * as inventory from '../../app/component/inventory'
import * as admin from '../../app/component/admin'
import * as staticpage from '../../app/component/staticpage'
import * as kyc from '../../app/component/kyc'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  client: client.reducer,
  dashboard: dashboard.reducer,
  role: role.reducer,
  apiKey: apiKey.reducer,
  shipmentPrice: shipmentPrice.reducer,
  buyRequest: buyRequest.reducer,
  order: order.reducer,
  inventory: inventory.reducer,
  admin: admin.reducer,
  staticpage: staticpage.reducer,
  kyc: kyc.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    role.saga(),
    client.saga(),
    dashboard.saga(),
    apiKey.saga(),
    shipmentPrice.saga(),
    buyRequest.saga(),
    order.saga(),
    inventory.saga(),
    admin.saga(),
    staticpage.saga(),
    kyc.saga()
  ])
}
