/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ChangeAdminSettings} from './ChangeAdminSettings'
import {ChangeClientSettings} from './ChangeClientSettings'
import {ChangePassword} from './ChangePassword'
import {ChangeBankAccountSettings} from './ChangeBankAccountSettings'
import {ChangeSystemSettings} from './ChangeSystemSettings'
import {Tabs} from 'antd'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

const {TabPane} = Tabs

const DisplaySetting: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <div>
      <div style={{marginBottom: '30px'}}>
        <ChangePassword />
      </div>

      {user?.role === 'admin' ? (
        <>
          <div style={{marginBottom: '30px'}}>
            <ChangeAdminSettings />
          </div>

          <div style={{marginBottom: '30px'}}>
            <ChangeBankAccountSettings />
          </div>

          <div style={{marginBottom: '30px'}}>
            <ChangeSystemSettings />
          </div>
        </>
      ) : (
        <div style={{marginBottom: '30px'}}>
          <ChangeClientSettings />
        </div>
      )}
    </div>
  )
}

export {DisplaySetting}
