import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {PictureOutlined} from '@ant-design/icons'
import {Button} from 'antd'
import ListUploadedMediaItem from '../../../modules/comman/ListComponenets/ListUploadedMediaItem'
import {config} from '../../../comman/constants'

interface IRenderFormikForm {
  values: IImportInventoryFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IImportInventoryFormValues {
  fileName: string
}

interface IImportInventoryProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

export class UploadKycDocumentForm extends React.Component<IImportInventoryProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      viewFileUploadModal: false,
      isLoading: false,
      uploadMedia: [],
    }
  }

  viewFileUploadModalRef: {show: () => void} | undefined

  public validateForm = (values: IImportInventoryFormValues) => {
    const errors: any = {}

    if (!values.fileName) {
      errors.fileName = 'Please select file'
    }

    return errors
  }

  public handleSubmit = (values: IImportInventoryFormValues, action: any) => {
    this.props.onSubmit(values,this.props.data._id)

    this.setState({
      visible: false,
    })
    this.props.handleCancel()
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public uploadMedia = (fileName: string, FileData: Array<any>, customFileData: Object) => {
    if (fileName && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
    }
    //close modal popup
    this.setState({
      viewFileUploadModal: false,
    })
  }

  public showMediaModal = (params: any) => {
    this.setState(
      {
        viewFileUploadModal: true,
      },
      () => {
        this.viewFileUploadModalRef?.show?.()
      }
    )
  }

  public render() {
    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  fileName: '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '100%',
                          marginTop: '20px',
                          alignItems: 'center',
                        }}
                      >
                        {/* {this.state.viewFileUploadModal ? ( */}
                        <InputSingleFileUpload
                          // ref={(e: any) => {
                          //   this.viewFileUploadModalRef = e
                          // }}
                          buttonLabel={'Choose KYC File'}
                          onBlur={handleBlur}
                          error={errors}
                          touched={touched}
                          name='file'
                          input={{
                            id: 'file',
                            name: config.uploadKycDocumentKey,
                            action: config.uploadKycDocumentPath,
                            headers: {
                              authorization: 'authorized-text',
                            },
                            accept: config.fileExtensionsForKyc,
                            acceptMimeTypes: config.fileExtensionsMimeTypesforKyc,
                            mediaType: config.uploadKycDocumentKey,
                            mediaModuleType: config.uploadKycDocumentKey,
                          }}
                          onUpload={(
                            fileName: string,
                            FileData: Array<any>,
                            customFileData: Object
                          ) => {
                            this.uploadMedia(fileName, FileData, customFileData)
                            setFieldValue(
                              'fileName',
                              fileName && FileData.length >= 1 ? fileName : ''
                            )
                          }}
                        />

                        {this.state.uploadMedia &&
                        this.state.uploadMedia[0] &&
                        this.state.uploadMedia.length >= 1 ? (
                          <ListUploadedMediaItem
                            name={this.state.uploadMedia[0].response.file}
                            url={`${config.importStockPreviewPath}${this.state.uploadMedia[0].response.file}`}
                          />
                        ) : null}
                      </div>
                      <div style={{marginTop: '20px'}}>
                        <InputButtonComponent onClick={handleSubmit}>Upload</InputButtonComponent>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
