/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ListWraaper} from './containers/List'
import {CreateWrapper} from './containers/CreateApiKey'

export function ApiKeyPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/apiKey/list' exact={true} component={ListWraaper} />
        <Route path='/apiKey/create' exact={true} component={CreateWrapper} />
        <Redirect from='/apiKey' exact={true} to='/apiKey/list' />
        <Redirect to='/apiKey/list' />
      </Switch>
    </div>
  )
}
