/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as inventory from '../../inventory/redux/InventoryRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {getShipMentPriceList} from '../../shipmentPrice/redux/ShipmentPriceCRUD'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
  returnStockString,
} from '../../../comman/helper'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor

interface IRecordsTableProps {
  inventoryCartData: any
  setInventoryCartData: (inventoryCartData: any) => void
  history?: History<LocationState>
  onSubmit: any
}

type PathParamsType = {
  param1: string
}
class ListCartData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  any
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      paymentMethod: 'CASH',
      shipmentData: [],
    }
  }

  totalPrice = 0
  totalDiscount = 0
  shipMentPrice = 0

  paymentMethods = [
    {label: 'Cash', value: 'CASH'},
    {label: 'Credit Card', value: 'CREDIT_CARD'},
    {label: 'Via Transfer', value: 'VIA_TRANSFER'},
  ]

  componentDidMount = () => {
    this.callInitialData()
  }

  // call initial data for Form
  public callInitialData = async () => {
    try {
      let shipMentList = await getShipMentPriceList()
      if (shipMentList.data) {
        this.setState({shipmentData: shipMentList.data})
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public createBuyRequest = () => {
    try {
      const {inventoryCartData, onSubmit} = this.props
      const {paymentMethod} = this.state

      this.setState({isLoading: true})

      let finalCartValue: any = []

      if (inventoryCartData) {
        inventoryCartData.map((item: any) => finalCartValue.push({stock_id: item._id}))
        finalCartValue = {diamonds: finalCartValue, payment_mode: paymentMethod}
        onSubmit(finalCartValue)
      }

      Toaster({
        type: 'success',
        title: 'BUY REQUEST',
        description: 'Buy Request created successfully',
      })
    } catch (e) {
      console.log('!!!!Error', e)
      this.setState({isLoading: false})
    } finally {
      console.log('!!!!finally')
      this.setState({isLoading: false})
    }
  }

  public updateCart = async (stockId: string) => {
    const {inventoryCartData, setInventoryCartData} = this.props
    try {
      this.setState({isLoading: true})
      let tempInventoryCartData: any
      if (inventoryCartData.length === 1) {
        this.deleteCart()
        return
      } else {
        const stockIndex = inventoryCartData.findIndex((item: any) => item.stock_id === stockId)
        if (stockIndex !== -1) {
          inventoryCartData.splice(stockIndex, 1)
          tempInventoryCartData = inventoryCartData
          await setInventoryCartData(tempInventoryCartData)
        }
      }
      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Cart Updated',
      })
    } catch (e) {
      console.log('!!!!Error', e)
      this.setState({isLoading: false})
    } finally {
      console.log('!!!!finally')
      this.setState({isLoading: false})
    }
  }

  public deleteCart = async () => {
    const {setInventoryCartData} = this.props
    try {
      await setInventoryCartData(undefined)

      Toaster({
        type: 'success',
        title: 'INVENTORY',
        description: 'Cart Updated',
      })
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      console.log('!!!!finally')
    }
  }

  public calculateTotal = () => {
    const {inventoryCartData} = this.props
    if (inventoryCartData && inventoryCartData.length >= 1) {
      this.totalPrice = 0
      this.totalDiscount = 0
      inventoryCartData.map((invItem: any) => {
        this.totalPrice += invItem.stock_data.final_price
        this.totalDiscount += invItem.stock_data.discounts
      })
    }
    return returnPriceInThousandFormatWithCurrencySign(this.totalPrice)
  }

  public calculateAvgDiscount = () => {
    const {inventoryCartData} = this.props
    return returnPriceInThousandFormat(this.totalDiscount / inventoryCartData.length)
  }

  public calculateShipMent = () => {
    const {shipmentData} = this.state

    if (shipmentData && shipmentData.length >= 1) {
      let getShipMentData = shipmentData.find(
        (shipItem: any) =>
          shipItem.from_number < this.totalPrice && shipItem.to_number > this.totalPrice
      )
      if (getShipMentData) {
        this.shipMentPrice = getShipMentData.price
      }
    }
    return returnPriceInThousandFormatWithCurrencySign(this.shipMentPrice)
  }

  public calculateGrandTotal = () => {
    let grandTotal = this.shipMentPrice + this.totalPrice
    return returnPriceInThousandFormatWithCurrencySign(grandTotal)
  }

  public onChangePaymentMethod = (status: string) => {
    this.setState({paymentMethod: status})
  }

  public render() {
    const {inventoryCartData} = this.props

    return (
      <div>
        <div>
          <div className='card card-custom card-fit mt-10'>
            <div className='card-header'>
              <h3 className='card-title table-card-title'>{'Cart'}</h3>
            </div>
            <div className='card-body'>
              {inventoryCartData && inventoryCartData.length >= 1 ? (
                <>
                  {/* <div style={{overflowX: 'auto'}}>
                    <div className='ant-table-wrapper'>
                      <div className='ant-spin-nested-loading'>
                        <div className='ant-spin-container'>
                          <div className='ant-table ant-table-small ant-table-ping-right ant-table-fixed-column ant-table-scroll-horizontal'>
                            <div className='ant-table-content custom-table-wrapper'>
                              <table
                                className='tableFixHead'
                                // style={{
                                //   width: '100%',
                                //   minWidth: '100%',
                                //   tableLayout: 'auto',
                                // }}
                              >
                                <thead className='ant-table-thead'>
                                  <tr>
                                    <th
                                      style={{maxWidth: '170px', width: '15%', minWidth: '120px'}}
                                    >
                                      {'Stock Id'}
                                    </th>
                                    <th>{'Details'}</th>
                                    <th
                                      className='text-right'
                                      style={{maxWidth: '150px', width: '15%', minWidth: '120px'}}
                                    >
                                      {'Discount(%)'}
                                    </th>
                                    <th
                                      className='text-right'
                                      style={{maxWidth: '150px', width: '12%', minWidth: '120px'}}
                                    >
                                      {'$/Ct'}
                                    </th>
                                    <th
                                      className='text-right'
                                      style={{maxWidth: '150px', width: '12%', minWidth: '120px'}}
                                    >
                                      {'Price'}
                                    </th>
                                    <th
                                      className='text-center'
                                      style={{maxWidth: '120px', width: '15%', minWidth: '80px'}}
                                    >
                                      {'Action'}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {inventoryCartData.map(
                                    (invItem: any, index: number) =>
                                      invItem && (
                                        <tr>
                                          <td
                                            className='ant-table-cell'
                                            key={index}
                                            style={{
                                              maxWidth: '170px',
                                              width: '15%',
                                              minWidth: '120px',
                                            }}
                                          >
                                            <div className='text-dark font-weight-bold'>
                                              <label>
                                                <a
                                                  href={`inventory/preview/${invItem.stock_data._id}`}
                                                  target='_blank'
                                                >
                                                  {returnStockId(invItem.stock_data.stock_id)}
                                                </a>
                                              </label>
                                            </div>
                                          </td>
                                          <td>
                                            <div className='text-dark font-weight-bold'>
                                              <label>{returnStockString(invItem.stock_data)}</label>
                                            </div>
                                          </td>

                                          <td
                                            style={{
                                              maxWidth: '150px',
                                              width: '12%',
                                              minWidth: '120px',
                                            }}
                                          >
                                            <div className='text-dark font-weight-bold'>
                                              <div className='text-right'>
                                                <label>{invItem.stock_data.discounts}</label>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              maxWidth: '150px',
                                              width: '12%',
                                              minWidth: '120px',
                                            }}
                                          >
                                            <div className='text-dark font-weight-bold'>
                                              <div className='text-right'>
                                                <label>{invItem.stock_data.price_per_caret}</label>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              maxWidth: '150px',
                                              width: '12%',
                                              minWidth: '120px',
                                            }}
                                          >
                                            <div className='text-dark font-weight-bold'>
                                              <div className='text-right'>
                                                <label>
                                                  {returnPriceInThousandFormatWithCurrencySign(
                                                    invItem.stock_data.final_price
                                                  )}
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              maxWidth: '120px',
                                              width: '15%',
                                              minWidth: '80px',
                                            }}
                                          >
                                            <div className='text-dark font-weight-bold d-flex justify-content-center'>
                                              <Tooltip placement='bottom' title='Delete'>
                                                <Popconfirm
                                                  onConfirm={() => {
                                                    //call delete client api
                                                    this.updateCart(invItem.stock_data.stock_id)
                                                  }}
                                                  title='Are you sure you want to remove this item from cart?'
                                                >
                                                  <Button
                                                    shape='circle'
                                                    icon={<DeleteOutlined />}
                                                  />
                                                </Popconfirm>
                                              </Tooltip>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                  )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td
                                      className='ant-table-cell border-0 p-2 text-right'
                                      colSpan={4}
                                    >
                                      <strong>{'Sub Total:'}</strong>
                                    </td>
                                    <td className='ant-table-cell border-0 p-2 text-right'>
                                      <strong>{this.calculateTotal()}</strong>
                                    </td>
                                    <td className='border-0 p-2' />
                                  </tr>
                                  <tr>
                                    <td
                                      className='ant-table-cell border-0 p-2 text-right'
                                      colSpan={4}
                                    >
                                      <strong>{'Discount:'}</strong>
                                    </td>
                                    <td className='ant-table-cell border-0 p-2 text-right'>
                                      <strong>{`${this.calculateAvgDiscount()}(%)`}</strong>
                                    </td>
                                    <td className='border-0 p-2' />
                                  </tr>
                                  <tr>
                                    <td
                                      className='ant-table-cell border-0 p-2 text-right'
                                      colSpan={4}
                                    >
                                      <strong>{'Shipment Price:'}</strong>
                                    </td>
                                    <td className='ant-table-cell border-0 p-2 text-right'>
                                      <strong>{this.calculateShipMent()}</strong>
                                    </td>
                                    <td className='border-0 p-2' />
                                  </tr>
                                  <tr>
                                    <td
                                      className='ant-table-cell border-0 p-2 text-right'
                                      colSpan={4}
                                    >
                                      <strong>{'Total'}</strong>
                                    </td>
                                    <td className='ant-table-cell border-0 p-2 text-right'>
                                      <strong>{this.calculateGrandTotal()}</strong>
                                    </td>
                                    <td className='border-0 p-2' />
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className='row border-bottom'>
                    <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                      {'Stock Id'}
                    </div>
                    <div className='col-lg-3 text-dark font-weight-bold text-muted'>
                      {'Details'}
                    </div>
                    <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                      <div className='text-right'>{'Discount(%)'}</div>
                    </div>
                    <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                      <div className='text-right'>{'$/Ct'}</div>
                    </div>
                    <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                      <div className='text-right'>{'Price'}</div>
                    </div>
                    <div className='col-lg-1 text-dark font-weight-bold text-muted'>{'Action'}</div>
                  </div>
                  <div style={{maxHeight: '450px', overflowY: 'scroll', overflowX: 'hidden'}}>
                    {inventoryCartData.map(
                      (invItem: any, index: number) =>
                        invItem && (
                          <div className='row mt-10' key={index}>
                            <div className='col-lg-3 text-dark font-weight-bold'>
                              <label>
                                <a
                                  href={`inventory/preview/${invItem.stock_data._id}`}
                                  target='_blank'
                                >
                                  {returnStockId(invItem.stock_data.stock_id)}
                                </a>
                              </label>
                            </div>
                            <div className='col-lg-3 text-dark font-weight-bold'>
                              <label>{returnStockString(invItem.stock_data)}</label>
                            </div>
                            <div className='col-lg-2 text-dark font-weight-bold'>
                              <div className='text-right'>
                                <label>{invItem.stock_data.discounts}</label>
                              </div>
                            </div>
                            <div className='col-lg-1 text-dark font-weight-bold'>
                              <div className='text-right'>
                                <label>{invItem.stock_data.price_per_caret}</label>
                              </div>
                            </div>
                            <div className='col-lg-2 text-dark font-weight-bold'>
                              <div className='text-right'>
                                <label>
                                  {returnPriceInThousandFormatWithCurrencySign(
                                    invItem.stock_data.final_price
                                  )}
                                </label>
                              </div>
                            </div>
                            <div className='col-lg-1 text-dark font-weight-bold'>
                              <Tooltip placement='bottom' title='Delete'>
                                <Popconfirm
                                  onConfirm={() => {
                                    //call delete client api
                                    this.updateCart(invItem.stock_data.stock_id)
                                  }}
                                  title='Are you sure you want to remove this item from cart?'
                                >
                                  <Button
                                    shape='circle'
                                    className='d-flex justify-content-center align-items-center'
                                    icon={<DeleteOutlined />}
                                  />
                                </Popconfirm>
                              </Tooltip>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                  <div className='row border-top mt-10'>
                    <div className='col-lg-9 text-dark font-weight-bold text-muted text-end'>
                      {'Sub Total:'}
                    </div>
                    <div className='col-lg-2 text-dark font-weight-bold'>
                      <div className='text-right'>{this.calculateTotal()}</div>
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='col-lg-9 text-dark font-weight-bold text-muted text-end'>
                      {'Discount:'}
                    </div>
                    <div className='col-lg-2 text-dark font-weight-bold'>
                      <div className='text-right'>{`${this.calculateAvgDiscount()}(%)`}</div>
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='col-lg-9 text-dark font-weight-bold text-muted text-end'>
                      {'Shipment Price:'}
                    </div>
                    <div className='col-lg-2 text-dark font-weight-bold'>
                      <div className='text-right'>{this.calculateShipMent()}</div>
                    </div>
                  </div>
                  <div className='row border-bottom mt-5'>
                    <div className='col-lg-9 text-dark font-weight-bold text-muted text-end'>
                      {'Total:'}
                    </div>
                    <div className='col-lg-2 text-dark font-weight-bold'>
                      <div className='text-right'>{this.calculateGrandTotal()}</div>
                    </div>
                  </div>

                  <div className='card-header p-0'>
                    <h3 className='card-title table-card-title'>{'Payment Method'}</h3>
                  </div>
                  <div className='mt-5 mb-5'>
                    <div className='col-lg-2 text-dark font-weight-bold p-0'>
                      <InputSingleSelect
                        input={{
                          value: this.state.paymentMethod,
                          id: 'payment_method',
                          name: 'payment_method',
                          options: this.paymentMethods,
                        }}
                        placeholder='Payment Method'
                        onChangeMethod={(value) => {
                          this.onChangePaymentMethod(value)
                        }}
                        label='Payment Method'
                        error={[]}
                        touched={[]}
                        clearable={false}
                      />
                    </div>
                  </div>
                  <div className='card-footer'></div>

                  <div className='d-flex justify-content-end mt-20'>
                    <div className='mr-5'>
                      <InputButtonComponent
                        onClick={() => {
                          this.createBuyRequest()
                        }}
                      >
                        {'Create Buy Request'}
                      </InputButtonComponent>
                    </div>
                    <div className=''>
                      <Popconfirm
                        onConfirm={() => {
                          //call delete client api
                          this.deleteCart()
                        }}
                        title='Are you sure you want to delete cart?'
                      >
                        <button type='button' className='btn btn-danger btn-sm'>
                          {'Clear Cart'}
                        </button>
                      </Popconfirm>
                    </div>
                  </div>
                </>
              ) : (
                <div className='row'>
                  <div className='col-md-12 text-center'>
                    <span className='alert alert-danger'>{'Cart is empty'}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  inventoryCartData: state.inventory.inventoryCartData,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setInventoryCartData: (inventoryCartData: any) => {
      dispatch(inventory.actions.setInventoryCartData(inventoryCartData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListCartData))
