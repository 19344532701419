import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setAdminData: 'SET_ADMIN_DATA',
  setAdminDataTotal: 'SET_ADMIN_DATA_TOTAL',
  setAdminLoading: 'SET_ADMIN_LOADER',
}

const initialClientState: IRoleState = {
  adminData: undefined,
  adminDataTotal: undefined,
  adminLoading: false,
}

export interface IRoleState {
  adminData?: any
  adminDataTotal?: number
  adminLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'admin', whitelist: ['adminData', 'adminDataTotal']},
  (state: IRoleState = initialClientState, action: ActionWithPayload<IRoleState>) => {
    switch (action.type) {
      case actionTypes.setAdminData: {
        const adminData = action.payload?.adminData
        return {...state, adminData}
      }

      case actionTypes.setAdminDataTotal: {
        const adminDataTotal = action.payload?.adminDataTotal
        return {...state, adminDataTotal}
      }

      case actionTypes.setAdminLoading: {
        const adminLoading = action.payload?.adminLoading
        return {...state, adminLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setAdminData: (adminData: Array<any>) => ({
    type: actionTypes.setAdminData,
    payload: {adminData: adminData},
  }),
  setAdminDataTotal: (adminDataTotal: number) => ({
    type: actionTypes.setAdminDataTotal,
    payload: {adminDataTotal},
  }),
  setAdminLoading: (adminLoading: boolean) => ({
    type: actionTypes.setAdminLoading,
    payload: {adminLoading},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.setAdminData, function* setAdminData() {
    yield put(actions.setAdminLoading(false))
  })
}
