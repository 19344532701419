import React from 'react'
import axios from 'axios'
import {ApiKeyModal} from '../models/ApiKeyModal'
import {IApiKeyFormValues} from '../containers/CreateApiKeyForm'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_API_KEY_URL = `${API_URL}v1/auth/admin/api-key/`
export const SEARCH_API_KEY_URL = `${API_URL}v1/auth/admin/filter-api-key/`
export const CREATE_API_KEY_URL = `${API_URL}v1/auth/api-key/`
export const EDIT_API_KEY_URL = `${API_URL}v1/auth/api-key/`
export const DELETE_API_KEY_URL = `${API_URL}v1/auth/api-key/`
export const CHANGE_STATUS_API_KEY_URL = `${API_URL}v1/auth/api-key/active-status/`

// Get Api Key Data
export async function getApiKeyData(startIndex: number, limitNumber: number) {
  try {
    let apiKeyData = await axios.get(`${GET_API_KEY_URL}${startIndex}/${limitNumber}`)
    return apiKeyData
  } catch (e) {
    throw e
  }
}

// Search Api Key Data
export async function getSearchApiKeyData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let apiKeyData = await axios.post(`${SEARCH_API_KEY_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return apiKeyData
  } catch (e) {
    throw e
  }
}

// Create Api Key Data
export async function createApiKeyData(apiKeyObject: IApiKeyFormValues) {
  try {
    let createApiKeyData = await axios.post(`${CREATE_API_KEY_URL}`, {
      ...apiKeyObject,
    })
    return createApiKeyData
  } catch (e) {
    throw e
  }
}

// Delete Api Key Data
export async function deleteApiKeyData(apiKeyId: string) {
  try {
    let deleteApiKeyData = await axios.delete(`${DELETE_API_KEY_URL}${apiKeyId}`)
    return deleteApiKeyData
  } catch (e) {
    throw e
  }
}

// Change Status Api Key Data
export async function changeStatusApiKeyData(apiKeyId: string, flag: boolean) {
  try {
    let apiKeyData = await axios.put(`${CHANGE_STATUS_API_KEY_URL}`, {
      apiKeyId,
      flag,
    })
    return apiKeyData
  } catch (e) {
    throw e
  }
}

// Edit Api Key Data
export async function editApiKeyData(apiKeyObject: IApiKeyFormValues, apiKeyId: string) {
  try {
    let editApiKeyData = await axios.put(`${EDIT_API_KEY_URL}`, {
      ...apiKeyObject,
      apiKeyId,
    })
    return editApiKeyData
  } catch (e) {
    throw e
  }
}
