/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {editAdminBankAccountDetails} from '../../../modules/auth/redux/AuthCRUD'
import {useHistory} from 'react-router'
import {InputToggle} from '../../../modules/comman/formComponents/InputToggle'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import * as auth from '../../../modules/auth/redux/AuthRedux'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {RootState} from '../../../../setup'

const validateSchema = Yup.object().shape({
  account_number: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Account Number is required'),
  routing_number: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Routing Number is required'),
})

const ChangeBankAccountSettings: FC = () => {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [hasErrorsString, setHasErrorsString] = useState<string | undefined>(undefined)

  const dispatch = useDispatch()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const initialValues = {
    account_number: user.account_details?.account_number ? user.account_details.account_number : "",
    routing_number: user.account_details?.routing_number ? user.account_details.routing_number : "",
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validateSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(async () => {
        try {
          if (values.account_number && values.routing_number) {
            let editAdminBankAccountData = await editAdminBankAccountDetails(values)
            console.log('!!!!!editAdminBankAccountData', editAdminBankAccountData)
            let adminData = {...editAdminBankAccountData}
            delete adminData.authToken
            dispatch(auth.actions.setUser(adminData))
            setHasErrors(false)
            setLoading(false)
            setTimeout(() => {
              window.location.reload()
            }, 100)
          }
        } catch (e: any) {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          setStatus('Please try again later')
          setHasErrorsString(
            typeof e === 'string'
              ? e
              : typeof e.message === 'string'
              ? e.message
              : 'Please check inputs and try again later'
          )
        }
      }, 1000)
    },
  })

  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Edit Bank Details</h3>
        </div>
        <div className='card-body'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            {/* begin::Title */}
            {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  {/* Sorry, looks like there are some errors detected, please try again. */}
                  {hasErrorsString}
                </div>
              </div>
            )}

            {hasErrors === false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>Edited SuccessFully</div>
              </div>
            )}
            {/* end::Title */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>Account Number</label>
                  {/* end::Label */}
                </div>
              </div>
              <input
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('account_number')}
                style={{width: '30%'}}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.account_number && formik.errors.account_number,
                  },
                  {
                    'is-valid': formik.touched.account_number && !formik.errors.account_number,
                  }
                )}
              />
              {formik.touched.account_number && formik.errors.account_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block text-danger'>
                    <span role='alert'>{formik.errors.account_number}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>Routing Number</label>
                  {/* end::Label */}
                </div>
              </div>
              <input
                type='number'
                autoComplete='off'
                {...formik.getFieldProps('routing_number')}
                style={{width: '30%'}}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.routing_number && formik.errors.routing_number,
                  },
                  {
                    'is-valid': formik.touched.routing_number && !formik.errors.routing_number,
                  }
                )}
              />
              {formik.touched.routing_number && formik.errors.routing_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block text-danger'>
                    <span role='alert'>{formik.errors.routing_number}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-start pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary fw-bolder me-4'
              >
                <span className='indicator-label'>Submit</span>
                {loading && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Form group */}
          </form>
        </div>
      </div>
    </div>
  )
}

export {ChangeBankAccountSettings}
