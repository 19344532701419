import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {ApiKeyWrapper} from '../pages/apiKey/ApiKeyWrapper'
import {BuyRequestWrapper} from '../pages/buyRequest/BuyRequestWrapper'
import {ClientWrapper} from '../pages/client/ClientWrapper'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {OrderWrapper} from '../pages/order/OrderWrapper'
import {RoleWrapper} from '../pages/role/RoleWrapper'
import {SettingWrapper} from '../pages/setting/SettingWrapper'
import {ShipmentPriceWrapper} from '../pages/shipmentPrice/ShipmentPriceWrapper'
import {InventoryWrapper} from '../pages/inventory/InventoryWrapper'
import {CartWrapper} from '../pages/cart/CartWrapper'
import {AdminWrapper} from '../pages/admin/AdminWrapper'
import {StaticPageWrapper} from '../pages/staticpage/StaticPageWrapper'
import {KycWrapper} from '../pages/kyc/KycWrapper'

export function PrivateRoutes() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* For Dashboard */}
        <Route path='/dashboard' exact component={DashboardWrapper} />

        {/* For Api Key */}
        <Route path='/apiKey' component={ApiKeyWrapper} />

        {/* For Shipment Price */}
        <Route path='/shipmentPrice' component={ShipmentPriceWrapper} />

        {/* For Role */}
        <Route path='/role' component={RoleWrapper} />

        {/* For Client */}
        {/* <Route path='/client' component={ClientWrapper} /> */}

        {/* For Admin */}
        <Route path='/admin' component={AdminWrapper} />

        {/* For Buy Request */}
        <Route path='/buy-request' component={BuyRequestWrapper} />

        {/* For Order */}
        <Route path='/order' component={OrderWrapper} />

        {/* For Inventory */}
        <Route path='/inventory' component={InventoryWrapper} />

        {/* For Cart */}
        <Route path='/cart' component={CartWrapper} />

        {/* For Setting */}
        <Route path='/setting' component={SettingWrapper} />

        <Route path='/staticpage' component={StaticPageWrapper} />

        <Route path='/kyc' component={KycWrapper} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect from='/user-setting' to='/setting' />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
