import React from 'react'
import axios from 'axios'
import {ShipmentPriceModal} from '../models/ShipmentPriceModal'
import {IShipmentPriceFormValues} from '../containers/CreateShipmentPriceForm'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_SHIPMENT_PRICE_URL = `${API_URL}v1/auth/shipment-price/`
export const GET_SHIPMENT_PRICE_URL_FOR_LIST = `${API_URL}v1/shipment-price/`
export const SEARCH_SHIPMENT_PRICE_URL = `${API_URL}v1/auth/filter-shipment-price/`
export const CREATE_SHIPMENT_PRICE_URL = `${API_URL}v1/auth/shipment-price/`
export const EDIT_SHIPMENT_PRICE_URL = `${API_URL}v1/auth/shipment-price/`
export const DELETE_SHIPMENT_PRICE_URL = `${API_URL}v1/auth/shipment-price/`
export const CHANGE_STATUS_SHIPMENT_PRICE_URL = `${API_URL}v1/auth/shipment-price/active-status/`

// Get Shipment Price Data
export async function getShipmentPriceData(startIndex: number, limitNumber: number) {
  try {
    let shipmentPriceData = await axios.get(`${GET_SHIPMENT_PRICE_URL}${startIndex}/${limitNumber}`)
    return shipmentPriceData
  } catch (e) {
    throw e
  }
}

// Search Shipment Price Data
export async function getSearchShipmentPriceData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let shipmentPriceData = await axios.post(
      `${SEARCH_SHIPMENT_PRICE_URL}${startIndex}/${limitNumber}`,
      {
        searchText,
      }
    )
    return shipmentPriceData
  } catch (e) {
    throw e
  }
}

// Get All ShipMent Price Data for DropDown
export async function getShipMentPriceList() {
  try {
    let shipMentPriceData = await axios.get(`${GET_SHIPMENT_PRICE_URL_FOR_LIST}`)
    return shipMentPriceData
  } catch (e) {
    throw e
  }
}

// Create Shipment Price Data
export async function createShipmentPriceData(shipmentPriceObject: IShipmentPriceFormValues) {
  try {
    let createShipmentPriceData = await axios.post(`${CREATE_SHIPMENT_PRICE_URL}`, {
      ...shipmentPriceObject,
    })
    return createShipmentPriceData
  } catch (e) {
    throw e
  }
}

// Delete Shipment Price Data
export async function deleteShipmentPriceData(shipmentPriceId: string) {
  try {
    let deleteShipmentPriceData = await axios.delete(
      `${DELETE_SHIPMENT_PRICE_URL}${shipmentPriceId}`
    )
    return deleteShipmentPriceData
  } catch (e) {
    throw e
  }
}

// Change Status Shipment Price Data
export async function changeStatusShipmentPriceData(shipmentPriceId: string, flag: boolean) {
  try {
    let shipmentPriceData = await axios.put(`${CHANGE_STATUS_SHIPMENT_PRICE_URL}`, {
      shipmentPriceId,
      flag,
    })
    return shipmentPriceData
  } catch (e) {
    throw e
  }
}

// Edit Shipment Price Data
export async function editShipmentPriceData(
  shipmentPriceObject: IShipmentPriceFormValues,
  shipmentPriceId: string
) {
  try {
    let editShipmentPriceData = await axios.put(`${EDIT_SHIPMENT_PRICE_URL}`, {
      ...shipmentPriceObject,
      shipmentPriceId,
    })
    return editShipmentPriceData
  } catch (e) {
    throw e
  }
}
