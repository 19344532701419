/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getStaticPageData} from '../redux/StaticPageCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as staticpage from '../redux/StaticPageRedux'
import ListStaticPageData from './ListStaticPageData'
import {RootState} from '../../../../setup'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const callStaticPageData = async () => {
    try {
      let bannerData
      bannerData = await getStaticPageData(0, 10)

      if (bannerData.data) {
        await dispatch(staticpage.actions.setStaticPageLoading(true))
        // once staticpage data set then loading will automatically off using saga middleware
        await dispatch(staticpage.actions.setStaticPageData(bannerData.data.docs))
        await dispatch(staticpage.actions.setStaticPageDataTotal(bannerData.data.total))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callStaticPageData()
  }, [])

  return (
    <div>
      <ListStaticPageData />
    </div>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/staticpage/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/staticpage/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.STATIC_PAGE'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
