/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as staticpage from '../redux/StaticPageRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons'
import {
  changeStatusStaticPageData,
  deleteStaticPageData,
  getStaticPageData,
  getSearchStaticPageData,
} from '../redux/StaticPageCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {CommanModal} from '../../../modules/comman/components/Modal'
import {EditStaticPageForm} from './EditStaticPageForm'
// import {EditBannerForm} from './EditBannerForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  staticPageData: any
  staticPageDataTotal: number
  staticPageLoading: boolean
  setListStaticPageData: (staticPageData: any) => void
  setListStaticPageDataTotal: (staticPageDataTotal: number) => void
  setStaticPageLoading: (staticPageLoading: boolean) => void
  history?: History<LocationState>
  loginUser?: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  staticPageSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListStaticPageData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      staticPageSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListStaticPageData(pagination)
    )
  }

  public getListStaticPageData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listStaticPageSearchData(start, end)
        } else {
          this.listStaticPageData(start, end)
        }
      }
    )
  }

  public listStaticPageData = async (start: number, end: any) => {
    const {setStaticPageLoading} = this.props
    try {
      const {setListStaticPageData, setListStaticPageDataTotal} = this.props
      await setStaticPageLoading(true)
      let staticPageData = await getStaticPageData(start, end)
      if (staticPageData.data) {
        await setListStaticPageData(staticPageData.data.docs)
        await setListStaticPageDataTotal(staticPageData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setStaticPageLoading(false)
    }
  }

  public listStaticPageSearchData = async (start: number, end: any) => {
    const {setStaticPageLoading} = this.props
    try {
      const {setListStaticPageData, setListStaticPageDataTotal} = this.props
      const {searchText} = this.state

      await setStaticPageLoading(true)
      let staticPageData = await getSearchStaticPageData(start, end, searchText)
      if (staticPageData.data) {
        await setListStaticPageData(staticPageData.data.docs)
        await setListStaticPageDataTotal(staticPageData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setStaticPageLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listStaticPageSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateStaticPage = () => {
    const {history} = this.props
    history.push('/staticpage/create')
  }

  public showPreviewModal = async (item: any) => {
    this.setState({
      staticPageSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      staticPageSelectedData: null,
    })
    if (this.state.searchText) {
      this.listStaticPageSearchData(this.state.startIndex, this.state.limitNumber)
    }
    this.listStaticPageData(this.state.startIndex, this.state.limitNumber)
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setStaticPageLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setStaticPageLoading(true)
      await changeStatusStaticPageData(item._id, checked)

      Toaster({
        type: 'success',
        title: 'STATIC PAGE',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listStaticPageSearchData(startIndex, limitNumber)
        return
      }
      this.listStaticPageData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setStaticPageLoading(false)
    }
  }

  public deleteStaticPage = async (item: any) => {
    const {setStaticPageLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setStaticPageLoading(true)
      await deleteStaticPageData(item._id)

      Toaster({
        type: 'success',
        title: 'STATIC PAGE',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listStaticPageSearchData(startIndex, limitNumber)
        return
      }
      this.listStaticPageData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setStaticPageLoading(false)
    }
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Name',
      key: 'name',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.name ? item.name : '-'}</strong>
          </div>
        )
      },
    },
    // {
    //   title: 'Description',
    //   key: 'description',
    //   ellipsis: true,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item?.description ? item.description : '-'}</div>
    //   },
    // },
    // {
    //   title: 'Order',
    //   key: 'order',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item?.order ? item.order : '-'}</div>
    //   },
    // },
    {
      title: 'isActive',
      key: 'isActive',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement='bottom' title='Static Page Activation'>
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
                    }}
                    onChange={(checked: boolean) => {
                      //call onChange Method with item
                      this.onChangeStatus(item, checked)
                    }}
                  />
                }
              </Tooltip>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit Static Page'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showPreviewModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete client api
                    this.deleteStaticPage(item)
                  }}
                  title='Are you sure you want to delete static page?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {staticPageData, staticPageDataTotal, staticPageLoading, loginUser} = this.props
    const {searchText, staticPageSelectedData} = this.state

    return (
      <div>
        <div>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateStaticPage()
                  }}
                >
                  Create Static Page
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  column={this.columns}
                  paginationConfig={{
                    total: staticPageDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={staticPageLoading}
                  dataSource={staticPageData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={false}
                  scrollWidth={1200}
                />
                <div>
                  <CommanModal
                    show={staticPageSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Static Page'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {staticPageSelectedData && (
                        <EditStaticPageForm
                          data={staticPageSelectedData}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                          onSubmit={() => {}}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  staticPageData: state.staticpage.staticPageData,
  staticPageDataTotal: state.staticpage.staticPageDataTotal,
  staticPageLoading: state.staticpage.staticPageLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListStaticPageData: (staticPageData: any) => {
      dispatch(staticpage.actions.setStaticPageData(staticPageData))
    },
    setListStaticPageDataTotal: (staticPageDataTotal: number) => {
      dispatch(staticpage.actions.setStaticPageDataTotal(staticPageDataTotal))
    },
    setStaticPageLoading: (staticPageLoading: boolean) => {
      dispatch(staticpage.actions.setStaticPageLoading(staticPageLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListStaticPageData))
