import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputToggle} from '../../../modules/comman/formComponents/InputToggle'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {Toaster} from '../../../modules/comman/components/Toaster'
import RichTextEditor from 'react-rte'
import {editStaticPageData} from '../redux/StaticPageCRUD'

interface IRenderFormikForm {
  values: IStaticPageFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IStaticPageFormValues {
  name: string
  description: string
}

interface IShipmentPriceProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export class EditStaticPageForm extends React.Component<IShipmentPriceProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      description: RichTextEditor.createEmptyValue(),
    }
  }

  async componentDidMount() {
    console.log('component did mount')
    if (this.props.data.description) {
      this.setState({
        description: RichTextEditor.createValueFromString(this.props.data.description, 'html'),
      })
    }
  }

  public validateForm = (values: IStaticPageFormValues) => {
    const errors: any = {}

    if (!values.name) {
      errors.name = 'Name is Required'
    }

    if (!values.description) {
      errors.description = 'Description is Required'
    }

    return errors
  }

  onDescriptionChange = (value: any) => {
    this.setState({description: value})
  }

  public handleSubmit = (values: IStaticPageFormValues, action: any) => {
    try {
      let EditStaticPageData = editStaticPageData(values, this.props.data._id)

      this.props.handleCancel()
      this.setState({
        visible: false,
      })
      Toaster({
        type: 'success',
        title: 'STATIC PAGE',
        description: 'Edited successFully',
      })
    } catch (e) {
      console.log(e)
      this.props.handleCancel()
      Toaster({
        type: 'error',
        title: 'STATIC PAGE',
        description: 'Failed to Edit',
      })
    }
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  name: this.props.data.name ? this.props.data.name : '',
                  description: this.props.data.description ? this.props.data.description : '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.name,
                              id: 'name',
                              name: 'name',
                            }}
                            placeholder='Enter Name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('name', value)
                            }}
                            onBlur={handleBlur}
                            label='Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div
                        className=''
                        style={{width: '100%', float: 'left', marginBottom: '20px'}}
                      >
                        Description
                        <RichTextEditor
                          value={this.state.description}
                          onChange={(e: any) => {
                            setFieldValue('description', e.toString('html'))
                            this.onDescriptionChange(e)
                          }}
                          placeholder={'Description'}
                        />
                        {errors.description && touched.description ? (
                          <p
                            style={{
                              color: 'red',
                              fontSize: 'small',
                              margin: '0',
                            }}
                          >
                            {errors.description}
                          </p>
                        ) : null}
                      </div>
                      <InputButtonComponent onClick={handleSubmit}>Edit</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
