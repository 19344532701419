/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

export function AsideMenuMain() {
  const intl = useIntl()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/dashboard.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
        canAccess={['admin', 'client']}
      />

      <AsideMenuItemWithSub
        to='/apiKey'
        title={intl.formatMessage({id: 'MENU.APIKEY'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/api-keys.svg'
        canAccess={['admin']}
      >
        <AsideMenuItem
          to='/apiKey/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
          canAccess={['admin']}
        />
        <AsideMenuItem
          to='/apiKey/create'
          title={intl.formatMessage({id: 'MENU.CREATE'})}
          hasBullet={true}
          canAccess={['admin']}
        />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItemWithSub
        to='/shipmentPrice'
        title={intl.formatMessage({id: 'MENU.SHIPMENTPRICE'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/shipment.svg'
        canAccess={['admin']}
      >
        <AsideMenuItem
          to='/shipmentPrice/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
          canAccess={['admin']}
        />
        <AsideMenuItem
          to='/shipmentPrice/create'
          title={intl.formatMessage({id: 'MENU.CREATE'})}
          hasBullet={true}
          canAccess={['admin']}
        />
      </AsideMenuItemWithSub> */}
      {/* 
      <AsideMenuItemWithSub
        to='/role'
        title={intl.formatMessage({id: 'MENU.ROLE'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/roles.svg'
        canAccess={['admin']}
      >
        <AsideMenuItem
          to='/role/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
          canAccess={['admin']}
        />
        <AsideMenuItem
          to='/role/create'
          title={intl.formatMessage({id: 'MENU.CREATE'})}
          hasBullet={true}
          canAccess={['admin']}
        />
      </AsideMenuItemWithSub> */}

      {/* <AsideMenuItemWithSub
        to='/client'
        title={intl.formatMessage({id: 'MENU.CLIENT'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/clients.svg'
        canAccess={['admin']}
      >
        <AsideMenuItem
          to='/client/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
          canAccess={['admin']}
        />
        <AsideMenuItem
          to='/client/create'
          title={intl.formatMessage({id: 'MENU.CREATE'})}
          hasBullet={true}
          canAccess={['admin']}
        />
      </AsideMenuItemWithSub> */}

      <AsideMenuItemWithSub
        to='/admin'
        title={intl.formatMessage({id: 'MENU.ADMIN'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/clients.svg'
        canAccess={['admin']}
      >
        <AsideMenuItem
          to='/admin/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
          canAccess={['admin']}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/staticpage'
        title={intl.formatMessage({id: 'MENU.STATIC_PAGE'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/roles.svg'
        canAccess={['admin']}
      >
        <AsideMenuItem
          to='/staticpage/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
          canAccess={['admin']}
        />
        <AsideMenuItem
          to='/staticpage/create'
          title={intl.formatMessage({id: 'MENU.CREATE'})}
          hasBullet={true}
          canAccess={['admin']}
        />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItemWithSub
        to='/buy-request'
        title={intl.formatMessage({id: 'MENU.BUYREQUEST'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/buy-request-alt.svg'
        canAccess={['admin', 'client']}
      >
        <AsideMenuItem
          to='/buy-request/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
          canAccess={['admin', 'client']}
        />
      </AsideMenuItemWithSub> */}

      {/* <AsideMenuItemWithSub
        to='/order'
        title={intl.formatMessage({id: 'MENU.ORDER'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/orders.svg'
        canAccess={['admin', 'client']}
      >
        <AsideMenuItem
          to='/order/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
          canAccess={['admin', 'client']}
        />
        // <AsideMenuItem
        //   to='/order/create'
        //   title={intl.formatMessage({id: 'MENU.CREATE'})}
        //   hasBullet={true}
        //   canAccess={['admin', 'client']}
        // />
      </AsideMenuItemWithSub> */}

      <AsideMenuItemWithSub
        to='/inventory'
        title={intl.formatMessage({id: 'MENU.INVENTORY'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/inventory.svg'
        canAccess={['admin', 'client']}
      >
        <AsideMenuItem
          to='/inventory/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
          canAccess={['admin', 'client']}
        />
        <AsideMenuItem
          to='/inventory/import'
          title={intl.formatMessage({id: 'MENU.IMPORTINVENTORY'})}
          hasBullet={true}
          canAccess={['admin']}
        />
        <AsideMenuItem
          to='/inventory/matrix'
          title={intl.formatMessage({id: 'MENU.MATRIXLIST'})}
          hasBullet={true}
          canAccess={['admin', 'client']}
        />
        <AsideMenuItem
          to='/inventory/twin'
          title={intl.formatMessage({id: 'MENU.TWINLIST'})}
          hasBullet={true}
          canAccess={['admin', 'client']}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/kyc'
        title={intl.formatMessage({id: 'MENU.PENDINGKYC'})}
        fontIcon='bi-app-indicator'
        icon='/media/icons/clients.svg'
        canAccess={['admin', 'client']}
      >
        <AsideMenuItem
          to='/kyc/list'
          title={intl.formatMessage({id: 'MENU.LIST'})}
          hasBullet={true}
          canAccess={['admin', 'client']}
        />
      </AsideMenuItemWithSub>

      {/*<AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      */}
    </>
  )
}
