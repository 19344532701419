import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'

interface IRenderFormikForm {
  values: IRoleFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IRoleFormValues {
  name: string
  rap_price_discount: number
}

interface IEditRoleProps {
  onSubmit: any
  data: any
  handleCancel?: any
}

export class EditRoleForm extends React.Component<IEditRoleProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IRoleFormValues) => {
    const errors: any = {}

    if (!values.name) {
      errors.name = 'Name is required'
    }

    if (values.rap_price_discount && values.rap_price_discount < 0) {
      errors.rap_price_discount = 'Rap price discount must be greater than 0'
    }

    return errors
  }

  public handleSubmit = (values: IRoleFormValues, action: any) => {
    const {data, handleCancel, onSubmit} = this.props
    onSubmit(values, data._id)
    handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    const {data} = this.props

    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  name: data.name,
                  rap_price_discount: data.rap_price_discount,
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div>
                      <div>
                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.name,
                                id: 'name',
                                name: 'name',
                              }}
                              placeholder='Enter name'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('name', value)
                              }}
                              onBlur={handleBlur}
                              label='Name'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputNumberText
                              input={{
                                value: values.rap_price_discount,
                                id: 'rap_price_discount',
                                name: 'rap_price_discount',
                              }}
                              placeholder='Enter rap price discount'
                              onChange={(value: number) => {
                                handleChange(value)
                                setFieldValue('rap_price_discount', value)
                              }}
                              onBlur={handleBlur}
                              label='Rap Price Discount'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='custom-card-footer'>
                        <div className='float-right'>
                          <InputButtonComponent onClick={handleSubmit}>Save</InputButtonComponent>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
