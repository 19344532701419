import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {getRoleDataForDropDown} from '../../role/redux/RoleCRUD'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'

interface IRenderFormikForm {
  values: IClientFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IClientFormValues {
  first_name: string
  last_name: string
  email: string
  username: string
  password: string
  company_name: string
  phone: string
  phone_iso: string
  address: string
  city: string
  state: string
  country: string
  postal_code: string
  role_id: string
}

interface IEditClientProps {
  onSubmit: any
  data: any
  handleCancel?: any
}

export class EditClientForm extends React.Component<IEditClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      roleData: [],
    }
  }

  public async UNSAFE_componentWillMount() {
    let roleData = await getRoleDataForDropDown()
    if (roleData.data) {
      let newRoleData = roleData.data?.map((data: any) => {
        return {
          label: data.name,
          value: data._id,
        }
      })
      this.setState({
        roleData: newRoleData,
      })
    }
  }

  public validateForm = (values: IClientFormValues) => {
    const errors: any = {}

    if (!values.first_name) {
      errors.first_name = 'First name is required'
    }

    if (!values.last_name) {
      errors.last_name = 'Last name is required'
    }

    if (!values.email) {
      errors.email = 'Email is required'
    }

    if (!values.username) {
      errors.username = 'Username is required'
    }

    if (!values.company_name) {
      errors.company_name = 'Company name is required'
    }

    if (!values.phone) {
      errors.phone = 'Phone is required'
    }

    if (!values.role_id) {
      errors.role_id = 'Role is required'
    }

    return errors
  }

  public handleSubmit = (values: IClientFormValues, action: any) => {
    const {data, handleCancel, onSubmit} = this.props
    values.phone_iso = values.phone.split('-').length > 0 ? values.phone.split('-')[0] : ''
    onSubmit(values, data._id)
    handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    const {data} = this.props
    const {roleData} = this.state

    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  first_name: data.first_name,
                  last_name: data.last_name,
                  email: data.email,
                  username: data.username,
                  password: '',
                  company_name: data.company_name,
                  phone: data.phone,
                  phone_iso: data.phone_iso,
                  address: data.address,
                  city: data.city,
                  state: data.state,
                  country: data.country,
                  postal_code: data.postal_code,
                  role_id: data.role_id,
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div>
                      <div>
                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.first_name,
                                id: 'first_name',
                                name: 'first_name',
                              }}
                              placeholder='Enter first name'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('first_name', value)
                              }}
                              onBlur={handleBlur}
                              label='First Name'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.last_name,
                                id: 'last_name',
                                name: 'last_name',
                              }}
                              placeholder='Enter last name'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('last_name', value)
                              }}
                              onBlur={handleBlur}
                              label='Last Name'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.email,
                                id: 'email',
                                name: 'email',
                              }}
                              placeholder='Enter email'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('email', value)
                              }}
                              onBlur={handleBlur}
                              label='Email'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.username,
                                id: 'username',
                                name: 'username',
                              }}
                              placeholder='Enter username'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('username', value)
                              }}
                              onBlur={handleBlur}
                              label='Username'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.password,
                                id: 'password',
                                name: 'password',
                              }}
                              placeholder='Enter password'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('password', value)
                              }}
                              onBlur={handleBlur}
                              label='Password'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputSingleSelect
                              input={{
                                value: values.role_id,
                                id: 'role_id',
                                name: 'role_id',
                                options: roleData,
                              }}
                              placeholder='Select Business Type'
                              onChangeMethod={(value) => {
                                setFieldValue('role_id', value)
                              }}
                              label='Select Business Type'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.phone,
                                id: 'phone',
                                name: 'phone',
                              }}
                              placeholder='Enter phone EX: (+91-6353689198)'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('phone', value)
                              }}
                              onBlur={handleBlur}
                              label='Phone'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.company_name,
                                id: 'company_name',
                                name: 'company_name',
                              }}
                              placeholder='Enter company name'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('company_name', value)
                              }}
                              onBlur={handleBlur}
                              label='Company Name'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.address,
                                id: 'address',
                                name: 'address',
                              }}
                              placeholder='Enter address'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('address', value)
                              }}
                              onBlur={handleBlur}
                              label='Address'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.city,
                                id: 'city',
                                name: 'city',
                              }}
                              placeholder='Enter city'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('city', value)
                              }}
                              onBlur={handleBlur}
                              label='City'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.state,
                                id: 'state',
                                name: 'state',
                              }}
                              placeholder='Enter state'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('state', value)
                              }}
                              onBlur={handleBlur}
                              label='State'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.country,
                                id: 'country',
                                name: 'country',
                              }}
                              placeholder='Enter country'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('country', value)
                              }}
                              onBlur={handleBlur}
                              label='Country'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.postal_code,
                                id: 'postal_code',
                                name: 'postal_code',
                              }}
                              placeholder='Enter posta code'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('postal_code', value)
                              }}
                              onBlur={handleBlur}
                              label='Postal Code'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='custom-card-footer'>
                        <div className='float-right'>
                          <InputButtonComponent onClick={handleSubmit}>Save</InputButtonComponent>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
