/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getAdminData} from '../redux/AdminCRUD'
import {useDispatch} from 'react-redux'
import * as admin from '../redux/AdminRedux'
import ListAdminData from './ListAdminData'
import {Tabs, Spin} from 'antd'
const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const [tabKey, setTabKey] = useState('1')
  const [isLoading, setIsLoading] = useState(false)

  const callAdminData = async (key: string) => {
    try {
      setIsLoading(true)
      let adminData = await getAdminData(0, 10, key)
      if (adminData.data) {
        await dispatch(admin.actions.setAdminLoading(true))
        // once admin data set then loading will automatically off using saga middleware
        await dispatch(admin.actions.setAdminData(adminData.data.docs))
        await dispatch(admin.actions.setAdminDataTotal(adminData.data.total))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
    finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    callAdminData('all')
  }, [])

  const onChangeTab = (key: string) => {
    console.log(key, typeof key, 'key')
    setTabKey(key)
    if(key === '3') {
      callAdminData('completed')
    }
    else if (key === '2') {
      callAdminData('pending')
    } else {
      callAdminData('all')
    }
  }

  return (
    <div>
      <Spin spinning={isLoading} tip='Loading...'>
        <ListAdminData />
        {/* <Tabs defaultActiveKey='1' onChange={onChangeTab} type='card'>
          <TabPane tab='All' key='1'>
            {tabKey === '1' && <ListAdminData tabKey='all' />}
          </TabPane>
          <TabPane tab='Pending' key='2'>
            {tabKey === '2' && <ListAdminData tabKey='pending' />}
          </TabPane>
          <TabPane tab='Completed' key='3'>
            {tabKey === '3' && <ListAdminData tabKey='completed' />}
          </TabPane>
        </Tabs> */}
      </Spin>
    </div>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/admin/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/admin/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.ADMIN'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
