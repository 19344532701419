import get from 'lodash/get';
import {Toaster} from '../../app/modules/comman/components/Toaster'
import {useDispatch} from 'react-redux'
import * as auth from '../../app/modules/auth/redux/AuthRedux'

export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => response.data,
    async (error: any) => {
      const originalRequest = error.config;
      const status = get(error, 'response.status');
      const errorKey = get(error, 'response.data.errorKey');
      const message = get(error, 'response.data.message');
      const payload = get(error, 'response.data.payload');

      console.log("!!!!!!error in callAPi function", originalRequest, status, errorKey, message, payload);

      if(status && status === 500) {
        Toaster({
          type: 'error',
          title : 'Server Error',
          description : typeof message === "string" ? message : 'Something Went Wrong, Please try again'
        })
      }

      if(status && status === 404) {
        Toaster({
          type: 'error',
          title : 'Server Error',
          description : typeof message === "string" ? message : 'Something Went Wrong, Please try again'
        })
      }

      if(status && status === 401) {
        Toaster({
          type: 'Error',
          title : 'UnAuthorize',
          description : typeof message === "string" ? message : 'You are not authorized, please login again'
        })
        const dispatch = useDispatch()
        await dispatch(auth.actions.logout())
        setTimeout(function () {
          document.location.reload()
        }, 1000)
      }

      // general error message
      return Promise.reject({
        originalRequest : originalRequest,
        status : status,
        message : message
      });
    },
  );

}
