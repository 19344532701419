/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createStaticPageData, getStaticPageData} from '../redux/StaticPageCRUD'
import * as staticpage from '../redux/StaticPageRedux'
import {CreateStaticPageForm} from './CreateStaticPageForm'
import {IStaticPageFormValues} from './CreateStaticPageForm'
import {Toaster} from '../../../modules/comman/components/Toaster'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const callBannerData = async () => {
    try {
      let shipmentPriceData = await getStaticPageData(0, 10)
      if (shipmentPriceData.data) {
        await dispatch(staticpage.actions.setStaticPageLoading(true))
        // once staticpage data set then loading will automatically off using saga middleware
        await dispatch(staticpage.actions.setStaticPageData(shipmentPriceData.data.docs))
        await dispatch(staticpage.actions.setStaticPageDataTotal(shipmentPriceData.data.total))
        history.push('/staticpage/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IStaticPageFormValues) => {
    try {
      await createStaticPageData(values)
      Toaster({
        type: 'success',
        title: 'Static Page',
        description: 'Static Page Created Successfully',
      })
      await callBannerData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <CreateStaticPageForm onSubmit={(values: IStaticPageFormValues) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Create',
            path: '/staticpage/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'Create',
            path: '/staticpage/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.STATIC_PAGE'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
