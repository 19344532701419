import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {ShipmentPriceModal} from '../models/ShipmentPriceModal'
import * as auth from '../../../modules/auth/redux/AuthRedux'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  setShipmentPriceData: 'SET_SHIPMENT_PRICE_DATA',
  setShipmentPriceDataTotal: 'SET_SHIPMENT_PRICE_DATA_TOTAL',
  setShipmentPriceLoading: 'SET_SHIPMENT_PRICE_LOADER'
}

const initialClientState: IShipmentPriceState = {
  shipmentPriceData: undefined,
  shipmentPriceDataTotal: undefined,
  shipmentPriceLoading: false
}

export interface IShipmentPriceState {
  shipmentPriceData?: ShipmentPriceModal
  shipmentPriceDataTotal?: number
  shipmentPriceLoading?: boolean
}

export const reducer = persistReducer(
  {storage, key: 'shipmentPrice', whitelist: ['shipmentPriceData', 'shipmentPriceDataTotal']},
    (state: IShipmentPriceState = initialClientState, action: ActionWithPayload<IShipmentPriceState>) => {
    switch (action.type) {
      case actionTypes.setShipmentPriceData: {
        const shipmentPriceData = action.payload?.shipmentPriceData
        return {...state, shipmentPriceData}
      }
            
      case actionTypes.setShipmentPriceDataTotal: {
        const shipmentPriceDataTotal = action.payload?.shipmentPriceDataTotal
        return {...state, shipmentPriceDataTotal}
      }
        
      case actionTypes.setShipmentPriceLoading: {
        const shipmentPriceLoading = action.payload?.shipmentPriceLoading
        return {...state, shipmentPriceLoading}
      }

      default:
        return state
    }
  }
)

export const actions = {
  setShipmentPriceData: (shipmentPriceData: Array<ShipmentPriceModal>) => ({type: actionTypes.setShipmentPriceData, payload: {shipmentPriceData : shipmentPriceData}}),
  setShipmentPriceDataTotal: (shipmentPriceDataTotal: number) => ({ type: actionTypes.setShipmentPriceDataTotal, payload: { shipmentPriceDataTotal } }),
  setShipmentPriceLoading: (shipmentPriceLoading: boolean) => ({type: actionTypes.setShipmentPriceLoading, payload: {shipmentPriceLoading}}),
}

export function* saga() {
  yield takeLatest(actionTypes.setShipmentPriceData, function* setShipmentPriceData() {
    yield put(actions.setShipmentPriceLoading(false))
  })
}
