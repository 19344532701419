/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getAllAdminKPIsData, getAllClientKPIsData} from '../redux/DashboardCRUD'
import {useDispatch, useSelector} from 'react-redux'
import * as dashboard from '../redux/DashboardRedux'
import {KpiCardComponent} from '../../../modules/comman/components/KpiCardComponent'

const DashboardPage: FC = () => {
  const dispatch = useDispatch()
  const dashboardDataStore = useSelector((state: any) => state.dashboard.dashboardData)
  const dashboardLoadingStore = useSelector((state: any) => state.dashboard.dashboardLoading)
  const auth = useSelector((state: any) => state.auth.user)

  const callInitDashboardData = async () => {
    try {
      let dashboardData
      if (auth.role === 'admin') {
        dashboardData = await getAllAdminKPIsData()
      } else {
        dashboardData = await getAllClientKPIsData()
      }
      if (dashboardData.data) {
        await dispatch(dashboard.actions.setDashboardLoading(true))
        // once dashboard data set then loading will automatically off using saga middleware
        await dispatch(dashboard.actions.setDashboardData(dashboardData.data))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitDashboardData()
  }, [])

  return (
    <div>
      {dashboardLoadingStore ? (
        <div></div>
      ) : (
        <div className='row g-5 g-xl-8'>
          {auth.role === 'admin' ? (
            <>
              <KpiCardComponent
                icon='/media/icons/buy-request-alt.svg'
                title='Total Buy Request'
                value={dashboardDataStore && String(dashboardDataStore.buyRequestData)}
                path='/buy-request/list'
                background='white'
              />
              <KpiCardComponent
                icon='/media/icons/orders.svg'
                title='Total Order'
                value={dashboardDataStore && String(dashboardDataStore.orderData)}
                path='/order/list'
                background='white'
              />
              <KpiCardComponent
                icon='/media/icons/inventory.svg'
                title='Total Stock'
                value={dashboardDataStore && String(dashboardDataStore.stockAvailableData)}
                path='/inventory/list'
                background='white'
              />
              <KpiCardComponent
                icon='/media/icons/inventory.svg'
                title='Hold Stock'
                value={dashboardDataStore && String(dashboardDataStore.stockHoldData)}
                path='/inventory/list'
                background='white'
              />
              <KpiCardComponent
                icon='/media/icons/inventory.svg'
                title='Sold Stock'
                value={dashboardDataStore && String(dashboardDataStore.stockSoldData)}
                path='/inventory/list'
                background='white'
              />
            </>
          ) : (
            <>
              <KpiCardComponent
                icon='/media/icons/inventory.svg'
                title='Total Stock'
                value={dashboardDataStore && String(dashboardDataStore.stockAvailableData)}
                path='/inventory/list'
                background='white'
              />
              <KpiCardComponent
                icon='/media/icons/inventory.svg'
                title='Hold Stock'
                value={dashboardDataStore && String(dashboardDataStore.stockHoldData)}
                path='/inventory/list'
                background='white'
              />
              <KpiCardComponent
                icon='/media/icons/inventory.svg'
                title='Sold Stock'
                value={dashboardDataStore && String(dashboardDataStore.stockSoldData)}
                path='/inventory/list'
                background='white'
              />
            </>
          )}

          {/* <KpiCardComponent
            icon='/media/icons/buy-request-alt.svg'
            title='New Customers'
            value='+3000'
            path='/client/list'
            background='black'
          />
          <KpiCardComponent
            icon='/media/icons/buy-request-alt.svg'
            title='New Customers'
            value='+3000'
            path='/client/list'
            background='yellow'
          />
          <KpiCardComponent
            icon='/media/icons/buy-request-alt.svg'
            title='New Customers'
            value='+3000'
            path='/client/list'
            background='blue'
          /> */}
        </div>
      )}
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Dashboard',
            path: '/dashboard',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'Dashboard',
            path: '/dashboard',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <DashboardPage />
    </div>
  )
}

export {DashboardWrapper}
