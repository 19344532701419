/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getInventoryData, getSearchInventoryData} from '../redux/InventoryCRUD'
import {useDispatch} from 'react-redux'
import * as inventory from '../redux/InventoryRedux'
import ListInventoryData from './ListInventoryData'
import ListSearchForm from './ListSearchForm'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const [searchPagination, setSearchPagination] = useState({
    skip: 0,
    limit: 10,
    filterObject: {},
  })

  const callInventoryData = async () => {
    try {
      let inventoryData = await getInventoryData(searchPagination.skip, searchPagination.limit)
      if (inventoryData.data) {
        await dispatch(inventory.actions.setInventoryLoading(true))
        // once inventory data set then loading will automatically off using saga middleware
        await dispatch(inventory.actions.setInventoryData(inventoryData.data.docs))
        await dispatch(inventory.actions.setInventoryDataTotal(inventoryData.data.total))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInventoryData()
  }, [])

  const searchInventoryData = async (values: any) => {
    try {
      await dispatch(inventory.actions.setInventoryLoading(true))
      let inventoryData = await getSearchInventoryData(
        searchPagination.skip,
        searchPagination.limit,
        values
      )
      if (inventoryData.data) {
        await dispatch(inventory.actions.setInventoryData(inventoryData.data.docs))
        await dispatch(inventory.actions.setInventoryDataTotal(inventoryData.data.total))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await dispatch(inventory.actions.setInventoryLoading(false))
    }
  }

  return (
    <div>
      <ListSearchForm onSubmit={searchInventoryData} onClearSearch={callInventoryData} />
      <ListInventoryData />
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/inventory/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/inventory/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.INVENTORY'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
