import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import moment from 'moment'
import {Tag} from 'antd'
import {config} from '../../../comman/constants'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
  returnStockString,
} from '../../../comman/helper'
import {getShipMentPriceList} from '../../shipmentPrice/redux/ShipmentPriceCRUD'

interface IPreviewOrderProps {
  data: any
  handleCancel?: any
  loginUserData?: any
}

export class PreviewOrderForm extends React.Component<IPreviewOrderProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  totalPrice = 0
  totalDiscount = 0
  shipMentPrice = 0

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  componentDidMount() {
    this.callInitialData()
  }

  // call initial data for Form
  public callInitialData = async () => {
    try {
      let shipMentList = await getShipMentPriceList()
      if (shipMentList.data) {
        this.setState({shipmentData: shipMentList.data})
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public calculateTotal = () => {
    const {data} = this.props
    if (
      data &&
      data.buy_request_id.stock_diamonds &&
      data.buy_request_id.stock_diamonds.length >= 1
    ) {
      this.totalPrice = 0
      this.totalDiscount = 0
      data.buy_request_id.stock_diamonds.map((invItem: any) => {
        this.totalPrice += invItem.stock_id.final_price
        this.totalDiscount += invItem.stock_id.discounts
      })
    }
    return returnPriceInThousandFormatWithCurrencySign(this.totalPrice)
  }

  public calculateAvgDiscount = () => {
    const {data} = this.props
    return returnPriceInThousandFormat(
      this.totalDiscount / data.buy_request_id.stock_diamonds.length
    )
  }

  public calculateShipMent = () => {
    const {shipmentData} = this.state

    if (shipmentData && shipmentData.length >= 1) {
      let getShipMentData = shipmentData.find(
        (shipItem: any) =>
          shipItem.from_number < this.totalPrice && shipItem.to_number > this.totalPrice
      )
      if (getShipMentData) {
        this.shipMentPrice = getShipMentData.price
      }
    }
    return returnPriceInThousandFormatWithCurrencySign(this.shipMentPrice)
  }

  public calculateGrandTotal = () => {
    let grandTotal = this.shipMentPrice + this.totalPrice
    return returnPriceInThousandFormatWithCurrencySign(grandTotal)
  }

  public render() {
    const {data, loginUserData} = this.props

    return (
      <div className='card card-custom'>
        {data ? (
          <>
            <div className='alert alert-primary text-center font-weight-bold' role='alert'>
              {'Please check your mail for further process'}
            </div>
            <div className='card-body'>
              <div>
                <div className='row mb-10'>
                  <div className='col-md-6 col-lg-6 col-sm-12'>
                    <h3>
                      {'Order Details'}
                      {` (${data.order_code && returnStockId(data.order_code)})`}
                    </h3>
                  </div>
                  <div className='col-md-3 col-lg-3 col-sm-12'>
                    <strong>
                      {data.buy_request_id?.status && (
                        <Tag
                          className='p-2'
                          color={
                            config.statusTagColor[data.buy_request_id.status]
                              ? config.statusTagColor[data.buy_request_id.status]
                              : 'default'
                          }
                        >
                          {data.buy_request_id.status}
                        </Tag>
                      )}
                    </strong>
                  </div>
                  <div className='col-md-3 col-lg-3 col-sm-12'>
                    <strong>
                      {data.order_date ? moment(data.order_date).format('DD-MM-YYYY') : '-'}
                    </strong>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='row'>
                    <div className='col-md-2 col-lg-2 col-sm-12 font-weight-bold text-muted'>
                      <label>{'Order Code:'}</label>
                    </div>
                    <div className='col-md-4 col-lg-4 col-sm-12'>
                      <label>{data.order_code ? returnStockId(data.order_code) : '-'}</label>
                    </div>

                    <div className='col-md-2 col-lg-2 col-sm-12 font-weight-bold text-muted'>
                      <label>{'Order Date:'}</label>
                    </div>
                    <div className='col-md-4 col-lg-4 col-sm-12'>
                      <label>
                        {data.order_date ? moment(data.order_date).format('DD-MM-YYYY') : '-'}
                      </label>
                    </div>
                  </div>
                  {loginUserData?.role === 'admin' && (
                    <>
                      <div className='row'>
                        <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                          <label>{'Name:'}</label>
                        </div>
                        <div className='col-lg-4'>
                          <label>
                            {data?.client_id?.first_name && data?.client_id?.last_name
                              ? `${data.client_id.first_name} ${data.client_id.last_name}`
                              : data.client_id.first_name
                              ? data.client_id.first_name
                              : '-'}
                          </label>
                        </div>
                        <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                          <label>{'Company:'}</label>
                        </div>
                        <div className='col-lg-4'>
                          <label>
                            {data?.client_id?.company_name ? data.client_id.company_name : '-'}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                          <label>{'Email:'}</label>
                        </div>
                        <div className='col-lg-4'>
                          <label>{data?.client_id?.email ? data.client_id.email : '-'}</label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className='row border-bottom'>
                  <h3 className='mb-10'>{'Stock Details'}</h3>
                  <div className='col-lg-2 text-dark font-weight-bold text-muted'>{'Stock Id'}</div>
                  <div className='col-lg-5 text-dark font-weight-bold text-muted'>{'Details'}</div>
                  <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                    <div className='text-right'>
                      <label>{'Discount'}</label>
                    </div>
                  </div>
                  <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                    <div className='text-right'>
                      <label>{'$/Ct'}</label>
                    </div>
                  </div>
                  <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                    <div className='text-right'>
                      <label>{'Price'}</label>
                    </div>
                  </div>
                </div>
                <div style={{maxHeight: '70vh', overflowX: 'hidden', overflowY: 'auto'}}>
                  {data.buy_request_id.stock_diamonds.map(
                    (invItem: any, index: number) =>
                      invItem && (
                        <div className='row mt-10' key={index}>
                          <div className='col-lg-2 text-dark font-weight-bold'>
                            <label>
                              <a
                                href={`/inventory/preview/${invItem.stock_id._id}`}
                                target='_blank'
                              >
                                {returnStockId(invItem.stock_id.stock_id)}
                              </a>
                            </label>
                          </div>
                          <div className='col-lg-5 text-dark font-weight-bold'>
                            <label>{returnStockString(invItem.stock_id)}</label>
                          </div>
                          <div className='col-lg-2 text-dark font-weight-bold'>
                            <div className='text-right'>
                              <label>{invItem.stock_id.discounts}</label>
                            </div>
                          </div>
                          <div className='col-lg-1 text-dark font-weight-bold'>
                            <div className='text-right'>
                              <label>{invItem.stock_id.price_per_caret}</label>
                            </div>
                          </div>
                          <div className='col-lg-2 text-dark font-weight-bold'>
                            <div className='text-right'>
                              <label>
                                {returnPriceInThousandFormatWithCurrencySign(
                                  invItem.stock_id.final_price
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
                <div className='row border-top mt-10'>
                  <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                    <label>{'Sub Total:'}</label>
                  </div>
                  <div className='col-lg-2 text-dark font-weight-bold'>
                    <div className='text-right'>
                      <label>{this.calculateTotal()} </label>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                    <label>{'Discount:'}</label>
                  </div>
                  <div className='col-lg-2 text-dark font-weight-bold'>
                    <div className='text-right'>
                      <label>{`${this.calculateAvgDiscount()}(%)`} </label>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                    <label>{'Shipment Price:'}</label>
                  </div>
                  <div className='col-lg-2 text-dark font-weight-bold'>
                    <div className='text-right'>
                      <label>{this.calculateShipMent()} </label>
                    </div>
                  </div>
                </div>
                <div className='row border-bottom'>
                  <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                    <label>{'Total:'}</label>
                  </div>
                  <div className='col-lg-2 text-dark font-weight-bold'>
                    <div className='text-right'>
                      <label>{this.calculateGrandTotal()} </label>
                    </div>
                  </div>
                </div>
                {/* <div className='row border-bottom'>
                  <div className='col-lg-6 font-weight-bold text-muted'>{'Stock Id'}</div>
                  <div className='col-lg-6 font-weight-bold text-muted'>
                    <div className='w-25 text-right'>{'Price'}</div>
                  </div>
                </div> */}
                {/* {data.buy_request_id && data.buy_request_id.stock_diamonds ? (
                  <div className='overflow-auto' style={{maxHeight: '300px'}}>
                    {data.buy_request_id.stock_diamonds.map((stockItem: any, index: number) => (
                      <div className='row mt-10' key={index}>
                        <div className='col-lg-6 text-dark font-weight-bold'>
                          <label>
                            <a
                              href={`/inventory/preview/${stockItem.stock_id._id}`}
                              target='_blank'
                            >
                              {returnStockId(stockItem.stock_id.stock_id)}
                            </a>
                          </label>
                        </div>
                        <div className='col-lg-6 text-dark font-weight-bold'>
                          <div className='w-25 text-right'>
                            <label>
                              {(Math.round(stockItem.stock_id.final_price * 100) / 100).toFixed(2)}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  '-'
                )}{' '} */}
              </div>
            </div>
          </>
        ) : (
          'Please wait'
        )}
      </div>
    )
  }
}
