/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getBuyRequestData} from '../redux/BuyRequestCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as buyRequest from '../redux/BuyRequestRedux'
import ListBuyRequestData from './ListBuyRequestData'
import {Tabs} from 'antd'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {RootState} from '../../../../setup'

const {TabPane} = Tabs

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const callBuyRequestData = async (status = '') => {
    try {
      let buyRequestData = await getBuyRequestData(0, 10, status)
      if (buyRequestData.data) {
        await dispatch(buyRequest.actions.setBuyRequestLoading(true))
        // once buyRequest data set then loading will automatically off using saga middleware
        await dispatch(buyRequest.actions.setBuyRequestData(buyRequestData.data.docs))
        await dispatch(buyRequest.actions.setBuyRequestDataTotal(buyRequestData.data.total))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  // const onChangeTab = (key: string) => {
  //   if (key === '1') {
  //     callBuyRequestData('UPDATED')
  //   } else {
  //     callBuyRequestData()
  //   }
  // }

  useEffect(() => {
    {
      user.role === 'admin' ? callBuyRequestData('PENDING') : callBuyRequestData()
    }
  }, [])

  return (
    <div>
      {/* <div className='container'> */}
      <ListBuyRequestData />
      {/* {user.role === 'admin' ? (
      ) : (
        <div className='card p-5'>
          <div className='card-container'>
            <Tabs defaultActiveKey='1' onChange={onChangeTab} type='card'>
              <TabPane tab='Updated Buy Request' key='1'>
                <ListBuyRequestData tabKey='1' />
              </TabPane>
              <TabPane tab='Pending Buy Request' key='2'>
                <ListBuyRequestData tabKey='2' />
              </TabPane>
            </Tabs>
          </div>
        </div>
      )} */}
      {/* </div> */}
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/buyRequest/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/buyRequest/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.BUYREQUEST'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
