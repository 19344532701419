import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputToggle} from '../../../modules/comman/formComponents/InputToggle'

interface IRenderFormikForm {
  values: IShipmentPriceFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IShipmentPriceFormValues {
  from_number: number
  to_number: number
  price: number
  is_usa: boolean
}

interface IShipmentPriceProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

export class CreateShipmentPriceForm extends React.Component<IShipmentPriceProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IShipmentPriceFormValues) => {
    const errors: any = {}

    if (!values.from_number || (values.from_number && values.from_number < 0)) {
      errors.from_number = 'From amount must be greater than 0'
    }

    if (!values.to_number || (values.to_number && values.to_number < 0)) {
      errors.to_number = 'To amount must be greater than 0'
    }

    if (!values.price || (values.price && values.price < 0)) {
      errors.price = 'Price must be greater than 0'
    }

    return errors
  }

  public handleSubmit = (values: IShipmentPriceFormValues, action: any) => {
    this.props.onSubmit(values)
    this.props.handleCancel()
    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title'>Create Shipment Price</h3>
          </div>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  from_number: 0,
                  to_number: 0,
                  price: 0,
                  is_usa: false,
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.from_number,
                              id: 'from_number',
                              name: 'from_number',
                            }}
                            placeholder='Enter from amount'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('from_number', value)
                            }}
                            onBlur={handleBlur}
                            label='From Amount'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.to_number,
                              id: 'to_number',
                              name: 'to_number',
                            }}
                            placeholder='Enter to amount'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('to_number', value)
                            }}
                            onBlur={handleBlur}
                            label='To Amount'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.price,
                              id: 'price',
                              name: 'price',
                            }}
                            placeholder='Enter price'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('price', value)
                            }}
                            onBlur={handleBlur}
                            label='Price'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputToggle
                            input={{
                              value: values.is_usa,
                              id: 'isUsa',
                              name: 'isUsa',
                            }}
                            onChange={(value: boolean) => {
                              setFieldValue('is_usa', value)
                            }}
                            label='In Usa'
                            error={errors}
                            touched={touched}
                            selectedLabel={'True'}
                            unSelectedlabel={'False'}
                          />
                        </div>
                      </div>

                      <InputButtonComponent onClick={handleSubmit}>Create</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
