/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as shipmentPrice from '../redux/ShipmentPriceRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {EditOutlined, DeleteOutlined} from '@ant-design/icons'
import {
  changeStatusShipmentPriceData,
  deleteShipmentPriceData,
  editShipmentPriceData,
  getShipmentPriceData,
  getSearchShipmentPriceData,
} from '../redux/ShipmentPriceCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {CommanModal} from '../../../modules/comman/components/Modal'
import {EditShipmentPriceForm} from './EditShipmentPriceForm'
import {IShipmentPriceFormValues} from './CreateShipmentPriceForm'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor

interface IRecordsTableProps {
  shipmentPriceData: any
  shipmentPriceDataTotal: number
  shipmentPriceLoading: boolean
  setListShipmentPriceData: (shipmentPriceData: any) => void
  setListShipmentPriceDataTotal: (shipmentPriceDataTotal: number) => void
  setShipmentPriceLoading: (shipmentPriceLoading: boolean) => void
  history?: History<LocationState>
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  shipmentPriceSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListShipmentPriceData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      shipmentPriceSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListShipmentPriceData(pagination)
    )
  }

  public getListShipmentPriceData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listShipmentPriceSearchData(start, end)
        } else {
          this.listShipmentPriceData(start, end)
        }
      }
    )
  }

  public listShipmentPriceData = async (start: number, end: any) => {
    const {setShipmentPriceLoading} = this.props
    try {
      const {setListShipmentPriceData, setListShipmentPriceDataTotal} = this.props
      await setShipmentPriceLoading(true)
      let shipmentPriceData = await getShipmentPriceData(start, end)
      if (shipmentPriceData.data) {
        await setListShipmentPriceData(shipmentPriceData.data.docs)
        await setListShipmentPriceDataTotal(shipmentPriceData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setShipmentPriceLoading(false)
    }
  }

  public listShipmentPriceSearchData = async (start: number, end: any) => {
    const {setShipmentPriceLoading} = this.props
    try {
      const {setListShipmentPriceData, setListShipmentPriceDataTotal} = this.props
      const {searchText} = this.state

      await setShipmentPriceLoading(true)
      let shipmentPriceData = await getSearchShipmentPriceData(start, end, searchText)
      if (shipmentPriceData.data) {
        await setListShipmentPriceData(shipmentPriceData.data.docs)
        await setListShipmentPriceDataTotal(shipmentPriceData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setShipmentPriceLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listShipmentPriceSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateShipmentPrice = () => {
    const {history} = this.props
    history.push('/shipmentPrice/create')
  }

  public deleteShipmentPrice = async (item: any) => {
    const {setShipmentPriceLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setShipmentPriceLoading(true)
      await deleteShipmentPriceData(item._id)

      Toaster({
        type: 'success',
        title: 'SHIPMENTPRICE',
        description: 'Deleted SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listShipmentPriceSearchData(startIndex, limitNumber)
        return
      }
      this.listShipmentPriceData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setShipmentPriceLoading(false)
    }
  }

  public onChangeStatus = async (item: any, checked: boolean) => {
    const {setShipmentPriceLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setShipmentPriceLoading(true)
      await changeStatusShipmentPriceData(item._id, checked)

      Toaster({
        type: 'success',
        title: 'SHIPMENTPRICE',
        description: 'Status changed successFully',
      })

      if (searchText != null && searchText !== '') {
        this.listShipmentPriceSearchData(startIndex, limitNumber)
        return
      }
      this.listShipmentPriceData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setShipmentPriceLoading(false)
    }
  }

  public onEditSubmit = async (values: IShipmentPriceFormValues, shipmentPriceId: string) => {
    const {setShipmentPriceLoading} = this.props
    try {
      const {startIndex, limitNumber, searchText} = this.state

      await setShipmentPriceLoading(true)
      await editShipmentPriceData(values, shipmentPriceId)

      Toaster({
        type: 'success',
        title: 'SHIPMENTPRICE',
        description: 'Edited SuccessFully',
      })

      if (searchText != null && searchText !== '') {
        this.listShipmentPriceSearchData(startIndex, limitNumber)
        return
      }
      this.listShipmentPriceData(startIndex, limitNumber)
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setShipmentPriceLoading(false)
    }
  }

  public showEditModal = async (item: any) => {
    this.setState({
      shipmentPriceSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      shipmentPriceSelectedData: null,
    })
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'From Amount',
      key: 'From Amount',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.from_number ? String(item.from_number) : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'To Amount',
      key: 'To Amount',
      render: (text: any, item: any, index: number) => {
        return <div>{item.to_number ? String(item.to_number) : '-'}</div>
      },
    },
    {
      title: 'Price',
      key: 'Price',
      render: (text: any, item: any, index: number) => {
        return <div>{item.price ? String(item.price) : '-'}</div>
      },
    },
    {
      title: 'isActive',
      key: 'isActive',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.is_active != null ? (
              <Tooltip placement='bottom' title='Shipment Price Activation'>
                {
                  <Switch
                    checked={item.is_active}
                    style={{
                      backgroundColor: item.is_active ? PRIMARY_COLOR : '#bababa',
                    }}
                    onChange={(checked: boolean) => {
                      //call onChange Method with item
                      this.onChangeStatus(item, checked)
                    }}
                  />
                }
              </Tooltip>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Edit'>
                <Button
                  shape='circle'
                  icon={<EditOutlined />}
                  onClick={() => {
                    this.showEditModal(item)
                  }}
                />
              </Tooltip>
            </div>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Delete'>
                <Popconfirm
                  onConfirm={() => {
                    //call delete client api
                    this.deleteShipmentPrice(item)
                  }}
                  title='Are you sure you want to delete Shipment Price?'
                >
                  <Button shape='circle' icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {shipmentPriceData, shipmentPriceDataTotal, shipmentPriceLoading} = this.props
    const {searchText, shipmentPriceSelectedData} = this.state

    return (
      <div>
        <div>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Shipment Price'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
              <div className='card-toolbar'>
                <InputButtonComponent
                  onClick={() => {
                    this.onCreateShipmentPrice()
                  }}
                >
                  Add Shipment Price
                </InputButtonComponent>
              </div>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  column={this.columns}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  paginationConfig={{
                    total: shipmentPriceDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={shipmentPriceLoading}
                  dataSource={shipmentPriceData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={false}
                />
                <div>
                  <CommanModal
                    show={shipmentPriceSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Edit Shipment Price'}
                    submitText={'Save Changes'}
                    // height={300}
                  >
                    <div>
                      {shipmentPriceSelectedData && (
                        <EditShipmentPriceForm
                          data={shipmentPriceSelectedData}
                          onSubmit={(values: IShipmentPriceFormValues, shipmentPriceId: string) => {
                            this.onEditSubmit(values, shipmentPriceId)
                          }}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  shipmentPriceData: state.shipmentPrice.shipmentPriceData,
  shipmentPriceDataTotal: state.shipmentPrice.shipmentPriceDataTotal,
  shipmentPriceLoading: state.shipmentPrice.shipmentPriceLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListShipmentPriceData: (shipmentPriceData: any) => {
      dispatch(shipmentPrice.actions.setShipmentPriceData(shipmentPriceData))
    },
    setListShipmentPriceDataTotal: (shipmentPriceDataTotal: number) => {
      dispatch(shipmentPrice.actions.setShipmentPriceDataTotal(shipmentPriceDataTotal))
    },
    setShipmentPriceLoading: (shipmentPriceLoading: boolean) => {
      dispatch(shipmentPrice.actions.setShipmentPriceLoading(shipmentPriceLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListShipmentPriceData))
