/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {CartPage} from '../../component/cart'

const CartWrapper: FC = () => {
  return (
    <div>
      <CartPage />
    </div>
  )
}

export {CartWrapper}
