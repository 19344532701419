import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export const GET_STATIC_PAGE_URL = `${API_URL}v1/auth/static-page/`
export const SEARCH_STATIC_PAGE_URL = `${API_URL}v1/auth/filter-static-page/`
export const CREATE_STATIC_PAGE_URL = `${API_URL}v1/auth/static-page/`
export const EDIT_STATIC_PAGE_URL = `${API_URL}v1/auth/static-page/`
export const DELETE_STATIC_PAGE_URL = `${API_URL}v1/auth/static-page/`
export const CHANGE_STATUS_STATIC_PAGE_URL = `${API_URL}v1/auth/static-page/active-status/`

// Get Order Data
export async function getStaticPageData(startIndex: number, limitNumber: number) {
  try {
    let staticpageData = await axios.get(`${GET_STATIC_PAGE_URL}${startIndex}/${limitNumber}`)
    return staticpageData
  } catch (e) {
    throw e
  }
}

// Search Order Data
export async function getSearchStaticPageData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let staticpageData = await axios.post(`${SEARCH_STATIC_PAGE_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return staticpageData
  } catch (e) {
    throw e
  }
}

// Create Order Data
export async function createStaticPageData(object: any) {
  try {
    let createStaticPage = await axios.post(`${CREATE_STATIC_PAGE_URL}`, {
      ...object,
    })
    return createStaticPage
  } catch (e) {
    throw e
  }
}

// Delete Order Data
export async function deleteStaticPageData(id: string) {
  try {
    let deleteData = await axios.delete(`${DELETE_STATIC_PAGE_URL}${id}`)
    return deleteData
  } catch (e) {
    throw e
  }
}

// Change Status Order Data
export async function changeStatusStaticPageData(staticPageId: string, flag: boolean) {
  try {
    let staticpageData = await axios.put(`${CHANGE_STATUS_STATIC_PAGE_URL}`, {
      staticPageId,
      flag,
    })
    return staticpageData
  } catch (e) {
    throw e
  }
}

// Edit Order Data
export async function editStaticPageData(orderObject: any, staticPageId: string) {
  try {
    let editPageData = await axios.put(`${EDIT_STATIC_PAGE_URL}`, {
      ...orderObject,
      staticPageId,
    })
    return editPageData
  } catch (e) {
    throw e
  }
}
