/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {register} from '../redux/AuthCRUD'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {getRoleDataUnAuthForDropDown} from '../../../component/role/redux/RoleCRUD'
import {useHistory} from 'react-router'
import {Toaster} from '../../comman/components/Toaster'

const initialValues = {
  role_id: '',
  first_name: '',
  last_name: '',
  email: '',
  username: '',
  password: '',
  confirmPassword: '',
  company_name: '',
  phone: '',
  phone_iso: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  role_id: Yup.string().required('Role is required'),
  first_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  last_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  company_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Company name is required'),
  phone: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(12, 'Maximum 12 symbols')
    .required('Phone no is required'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const [roleData, setRoleData] = useState([])

  //call initial data
  const callInitialData = async () => {
    try {
      let roleData = await getRoleDataUnAuthForDropDown()
      if (roleData.data) {
        let newRoleData = roleData.data.map((data: any) => ({label: data.name, value: data._id})) //set the role data as per the require single select format
        setRoleData(newRoleData) //set updated role data to the state
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        register(
          values.role_id,
          values.first_name,
          values.last_name,
          values.email,
          values.username,
          values.password,
          values.company_name,
          values.phone,
          (values.phone_iso = values.phone.split('-').length > 0 ? values.phone.split('-')[0] : '')
        )
          .then(() => {
            setLoading(false)
            // dispatch(auth.actions.login(authToken, {}))
            history.push('/auth/login')
            Toaster({
              type: 'success',
              title: 'Registration',
              description: 'Client registered successFully',
            })
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Please try after some time')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Create an Account</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Already have an account?
          </Link>
          {/* <Link to='/auth/fo' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Forgot Password ?
          </Link> */}
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group first_name */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>First name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('first_name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.first_name && formik.errors.first_name,
              },
              {
                'is-valid': formik.touched.first_name && !formik.errors.first_name,
              }
            )}
          />
          {formik.touched.first_name && formik.errors.first_name && (
            <div className='fv-plugins-message-container text-danger'>
              <div className='fv-help-block'>{formik.errors.first_name}</div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group last_name */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
            <input
              placeholder='Last name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('last_name')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.last_name && formik.errors.last_name,
                },
                {
                  'is-valid': formik.touched.last_name && !formik.errors.last_name,
                }
              )}
            />
            {formik.touched.last_name && formik.errors.last_name && (
              <div className='fv-plugins-message-container text-danger'>
                <div className='fv-help-block'>{formik.errors.last_name}</div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group User name Role Id */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>User name</label>
          <input
            placeholder='User name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('username')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.username && formik.errors.username,
              },
              {
                'is-valid': formik.touched.username && !formik.errors.username,
              }
            )}
          />
          {formik.touched.username && formik.errors.username && (
            <div className='fv-plugins-message-container text-danger'>
              <div className='fv-help-block'>{formik.errors.username}</div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group last_name */}
          <div className='fv-row mb-5'>
            {/* <label className='form-label fw-bolder text-dark fs-6'>Role</label> */}
            <InputSingleSelect
              input={{
                value: formik.values.role_id,
                id: 'role_id',
                name: 'role_id',
                options: roleData,
              }}
              placeholder='Select Business Type'
              onChangeMethod={(value) => {
                formik.setFieldValue('role_id', value)
              }}
              label='Select Business Type'
              error={formik.errors}
              touched={formik.touched}
            />
            {formik.touched.role_id && formik.errors.role_id && (
              <div className='fv-plugins-message-container text-danger'>
                <div className='fv-help-block'>{formik.errors.role_id}</div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}

      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>
            <input
              placeholder='Email'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container text-danger'>
                <div className='fv-help-block'>{formik.errors.email}</div>
              </div>
            )}
          </div>
        </div>
        <div className='col-xl-6'>
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Company Name</label>
            <input
              placeholder='Company Name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('company_name')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.company_name && formik.errors.company_name,
                },
                {
                  'is-valid': formik.touched.company_name && !formik.errors.company_name,
                }
              )}
            />
            {formik.touched.company_name && formik.errors.company_name && (
              <div className='fv-plugins-message-container text-danger'>
                <div className='fv-help-block'>{formik.errors.company_name}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password and reset password */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container text-danger'>
                <div className='fv-help-block'>{formik.errors.password}</div>
              </div>
            )}
          </div>
        </div>
        <div className='col-xl-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder='Confirm Password'
            autoComplete='off'
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
              },
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container text-danger'>
              <div className='fv-help-block'>{formik.errors.confirmPassword}</div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group Password and reset password */}

      {/* begin::Form group Phone */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Phone</label>
          <div className='position-relative mb-3'>
            <input
              type='text'
              placeholder='Phone'
              autoComplete='off'
              {...formik.getFieldProps('phone')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.phone && formik.errors.phone,
                },
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
              )}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container text-danger'>
                <div className='fv-help-block'>{formik.errors.phone}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group Password and reset password */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6 ml-5'
            htmlFor='kt_login_toc_agree'
          >
            {'I agree the '}
            <a href='/terms' target='_blank' className='ms-1 link-primary'>
              {'Terms and Conditions'}
            </a>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container text-danger'>
              <div className='fv-help-block'>{formik.errors.acceptTerms}</div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
