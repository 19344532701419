/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {importInventoryData, getInventoryData} from '../redux/InventoryCRUD'
import * as inventory from '../redux/InventoryRedux'
import {IImportInventoryFormValues, ImportInventoryForm} from './ImportInventoryForm'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const callInventoryData = async () => {
    try {
      let inventoryData = await getInventoryData(0, 10)
      if (inventoryData.data) {
        await dispatch(inventory.actions.setInventoryLoading(true))
        // once inventory data set then loading will automatically off using saga middleware
        await dispatch(inventory.actions.setInventoryData(inventoryData.data.docs))
        await dispatch(inventory.actions.setInventoryDataTotal(inventoryData.data.total))
        history.push('/inventory/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IImportInventoryFormValues) => {
    try {
      await importInventoryData(values)
      await callInventoryData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <ImportInventoryForm onSubmit={(values: IImportInventoryFormValues) => addSubmit(values)} />
    </div>
  )
}

const ImportWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'import',
            path: '/inventory/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'import',
            path: '/inventory/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.INVENTORY'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ImportWrapper}
