import React, {FC} from 'react'
import {History, LocationState} from 'history'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {connect} from 'react-redux'
import {config} from '../../../comman/constants'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
} from '../../../comman/helper'
import {EyeOutlined} from '@ant-design/icons'
import {Tooltip, Popconfirm, Input, Tag} from 'antd'
import {Button} from 'antd'
import * as inventory from '../redux/InventoryRedux'

interface IRecordsListItemProps {
  data: any
  randomNumber?: number | string
  history?: History<LocationState>
  addInventoryItemToCart: (event: any, invData: any) => void
  inventoryCartData: any
  loginUser: any
}

type PathParamsType = {
  param1: string
}

interface IRecordsListItemState {
  setInventoryCartData: (inventoryCartData: any) => void
}

class ListTwinInventoryDataItem extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsListItemProps,
  IRecordsListItemState
> {
  constructor(props: any) {
    super(props)
  }

  componentWillUnmount() {
    this.invItemData = []
  }

  public previewInventoryForm = (item: any) => {
    const {history} = this.props
    history.push('/inventory/preview/' + item._id)
  }

  invItemData: any = []

  public renderCheckBox = (invItem: any, index: number) => {
    const {inventoryCartData} = this.props
    let tempInvIndex = this.invItemData.findIndex((item: any) => item.stock_id === invItem.stock_id)
    if (tempInvIndex === -1) {
      this.invItemData.push({
        _id: invItem._id,
        stock_id: invItem.stock_id,
        stock_data: invItem,
      })
    }
    if (index === 0) {
      return (
        <td
          // className='ant-table-cell ant-table-selection-column ant-table-cell-fix-left custom-table-sticky-col custom-table-first-col'
          className='ant-table-cell custom-table-sticky-col custom-table-first-col'
          rowSpan={2}
        >
          <label
            className={`ant-checkbox-wrapper ${
              invItem.status !== 'AVAILABLE' ? 'ant-checkbox-wrapper-disabled' : ''
            } ${
              inventoryCartData &&
              inventoryCartData.length >= 1 &&
              inventoryCartData.findIndex((item: any) => item.stock_id === invItem.stock_id) !== -1
                ? 'ant-checkbox-checked-wrapper'
                : ''
            }`}
          >
            <span
              className={`ant-checkbox ${
                invItem.status !== 'AVAILABLE' ? 'ant-checkbox-disabled' : ''
              } ${
                inventoryCartData &&
                inventoryCartData.length >= 1 &&
                inventoryCartData.findIndex((item: any) => item.stock_id === invItem.stock_id) !==
                  -1
                  ? 'ant-checkbox-checked'
                  : ''
              }`}
            >
              {console.log()}
              <input
                type='checkbox'
                disabled={invItem.status !== 'AVAILABLE'}
                className='ant-checkbox-input'
                value={invItem._id}
                onChange={(e) => this.props.addInventoryItemToCart(e, this.invItemData)}
                checked={
                  inventoryCartData &&
                  inventoryCartData.length >= 1 &&
                  inventoryCartData.findIndex((item: any) => item.stock_id === invItem.stock_id) !==
                    -1
                }
              />
              <span className='ant-checkbox-inner'></span>
            </span>
          </label>
        </td>
      )
    }
  }

  public render() {
    const {data, loginUser} = this.props

    return (
      <>
        {/*all-group-listing*/}
        {data.docs.map(
          (invItem: any, index: number) =>
            index < 2 && (
              <tr
                className={`${
                  index === 1
                    ? 'ant-table-row ant-table-row-level-0 border-bottom'
                    : 'ant-table-row ant-table-row-level-0'
                }`}
                key={index}
              >
                {loginUser?.role !== 'admin' && this.renderCheckBox(invItem, index)}

                <td
                  className={`${
                    index === 1
                      ? 'ant-table-cell custom-table-sticky-col custom-table-second-col'
                      : 'ant-table-cell custom-table-sticky-col custom-table-second-col border-0'
                  }`}
                >
                  <div>
                    <a href={`/inventory/preview/${invItem._id}`} target='_blank'>
                      <strong>{invItem.stock_id ? returnStockId(invItem.stock_id) : '-'}</strong>
                    </a>
                  </div>
                </td>

                <td
                  className={`${
                    index === 1
                      ? 'ant-table-cell custom-table-sticky-col custom-table-third-col'
                      : 'ant-table-cell custom-table-sticky-col custom-table-third-col border-0'
                  }`}
                >
                  <div>
                    {' '}
                    {invItem.status ? (
                      <Tag
                        color={
                          config.statusTagColor[invItem.status]
                            ? config.statusTagColor[invItem.status]
                            : 'default'
                        }
                      >
                        {invItem.status}
                      </Tag>
                    ) : (
                      '-'
                    )}
                  </div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>
                    {invItem.diamond_image ? (
                      <a href={invItem.diamond_image} target='_blank'>
                        {invItem.diamond_image}
                      </a>
                    ) : (
                      <span className='text-muted'>{'No Image Available'}</span>
                    )}
                    {' | '}
                    {invItem.diamond_video ? (
                      <a href={invItem.diamond_video} target='_blank'>
                        {invItem.diamond_video}
                      </a>
                    ) : (
                      <span className='text-muted'>{'No Video Available'}</span>
                    )}
                  </div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.shape ? returnStockId(invItem.shape) : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.weight ? invItem.weight : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.color ? returnStockId(invItem.color) : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.clarity ? returnStockId(invItem.clarity) : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.cut ? returnStockId(invItem.cut) : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.polish ? returnStockId(invItem.polish) : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.symmetry ? returnStockId(invItem.symmetry) : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>
                    {invItem.fluorescence_intensity
                      ? returnStockId(invItem.fluorescence_intensity)
                      : '-'}
                  </div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.lab_grown_type ? invItem.lab_grown_type : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.certificate_number ? invItem.certificate_number : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.discounts ? invItem.discounts : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.price_per_caret ? invItem.price_per_caret : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>
                    {invItem.final_price
                      ? returnPriceInThousandFormatWithCurrencySign(invItem.final_price)
                      : '-'}
                  </div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>
                    {invItem.final_price_ori
                      ? returnPriceInThousandFormatWithCurrencySign(invItem.final_price_ori)
                      : '-'}
                  </div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.status ? returnStockId(invItem.status) : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.brand ? returnStockId(invItem.brand) : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.crown_angle ? invItem.crown_angle : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.crown_height ? invItem.crown_height : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>
                    {invItem.culet_condition ? returnStockId(invItem.culet_condition) : '-'}
                  </div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.depth ? invItem.depth : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.diamond_type ? returnStockId(invItem.diamond_type) : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>
                    {invItem.girdle_condition ? returnStockId(invItem.girdle_condition) : '-'}
                  </div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.lw_ratio ? invItem.lw_ratio : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.meas_depth ? invItem.meas_depth : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.measurements ? invItem.measurements : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.pavilion_angle ? invItem.pavilion_angle : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.pavilion_depth ? invItem.pavilion_depth : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>
                    {invItem.rap_per_caret
                      ? returnPriceInThousandFormat(invItem.rap_per_caret)
                      : '-'}
                  </div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.rap_price ? invItem.rap_price : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.table ? invItem.table : '-'}</div>
                </td>

                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div>{invItem.treatment ? returnStockId(invItem.treatment) : '-'}</div>
                </td>
                <td className={`${index === 1 ? 'ant-table-cell' : 'ant-table-cell border-0'}`}>
                  <div style={{float: 'left', marginRight: '5px'}}>
                    <Tooltip placement='bottom' title='View'>
                      <Button
                        shape='circle'
                        icon={<EyeOutlined />}
                        onClick={() => {
                          this.previewInventoryForm(invItem)
                        }}
                      />
                    </Tooltip>
                  </div>
                </td>
              </tr>
            )
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  inventoryCartData: state.inventory.inventoryCartData,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setInventoryCartData: (inventoryCartData: any) => {
      dispatch(inventory.actions.setInventoryCartData(inventoryCartData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListTwinInventoryDataItem))
