/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {verifyEmail} from '../redux/AuthCRUD'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Toaster} from '../../comman/components/Toaster'

export function VerifyEmail(props: any) {
  const userId = props.match.params.userId

  const [verifiedProcess, setVerifiedProcess] = useState('start')

  useEffect(() => {
    setTimeout(async () => {
      try {
        let verifyEmailData = await verifyEmail(userId)
        if (verifyEmailData.status === 200) {
          Toaster({
            type: 'success',
            title: 'EMAIL VERIFICATION',
            description: 'Your Email has been verified, please login',
          })
          const {history} = props
          history.push('/auth/login/')
          // setVerifiedProcess('verified')
        } else {
          Toaster({
            type: 'error',
            title: 'EMAIL VERIFICATION',
            description: 'Invalid user, please try again later',
          })
          const {history} = props
          history.push('/auth/login/')
          // setVerifiedProcess('notverified')
        }
      } catch (e: any) {
        Toaster({
          type: 'error',
          title: 'EMAIL VERIFICATION',
          description:
            typeof e === 'string' ? e : typeof e.message === 'string' ? e.message : 'Invalid user',
        })
        const {history} = props
        history.push('/auth/login/')
      }
    }, 1000)
  })

  return (
    <div>
      <div className='d-flex justify-content-center text-center'>
        {/* {verifiedProcess === 'notverified' ? (
          <div className='ms-3'>{'Please wait, verifying mail ID is in process'}</div>
        ) : (
          <>
          </>
        )} */}
        <div className='spinner-border'></div>
        <div className='ms-3'>{'Please wait, verifying mail ID is in process'}</div>
      </div>
    </div>
  )
}
