import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {returnPriceInThousandFormat, returnPriceInThousandFormatWithCurrencySign, returnStockId, returnStockString} from '../../../comman/helper'
import {Tag} from 'antd'
import {config} from '../../../comman/constants'
import {getShipMentPriceList} from '../../shipmentPrice/redux/ShipmentPriceCRUD'

interface IRenderFormikForm {
  values: IBuyRequestFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IBuyRequestFormValues {
  status?: string
  diamonds?: {stock_id: number; is_available: boolean}
}

interface IEditBuyRequestProps {
  onSubmit: any
  data: any
  handleCancel?: any
  loginUserData?: any
}

export class EditBuyRequestForm extends React.Component<IEditBuyRequestProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      updatedStockData: [],
    }
  }

  totalPrice = 0
  totalDiscount = 0
  shipMentPrice = 0

  buyRequestStatus = [
    {label: 'Accepted', value: 'ACCEPTED'},
    {label: 'Cancelled', value: 'CANCELLED'},
    {label: 'Updated', value: 'UPDATED'},
    {label: 'Pending', value: 'PENDING'},
  ]

  stockAvailableStatus = [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ]

  public validateForm = (values: IBuyRequestFormValues) => {
    const errors: any = {}

    console.log(errors)

    return errors
  }

  public handleSubmit = (values: IBuyRequestFormValues, action: any) => {
    const {data, handleCancel, onSubmit} = this.props
    values.diamonds = this.state.updatedStockData
    onSubmit(values, data._id)
    handleCancel()

    this.setState({
      visible: false,
      shipmentData: [],
    })
  }

  componentDidMount() {
    this.initialStockStatus()
    this.callInitialData()
  }

  initialStockStatus = () => {
    const {data} = this.props

    if (data && data.stock_diamonds.length >= 1) {
      let tempStockData: any = []
      data.stock_diamonds.map((stockItem: any, index: number) => {
        tempStockData.push({
          _id: stockItem._id,
          is_available: stockItem.is_available,
          stock_id: stockItem.stock_id.stock_id,
          stockId_id: stockItem.stock_id._id,
          stock_data: stockItem.stock_id,
        })
      })
      this.setState({updatedStockData: tempStockData})
    }
  }

  // call initial data for Form
  public callInitialData = async () => {
    try {
      let shipMentList = await getShipMentPriceList()
      if (shipMentList.data) {
        this.setState({shipmentData: shipMentList.data})
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public updateBuyRequest = (status: any) => {
    const {data, handleCancel, onSubmit} = this.props
    const {updatedStockData} = this.state

    if (updatedStockData && updatedStockData.length >= 1) {
      let formValues: any = {}

      formValues.status = status

      if (status === 'UPDATED') {
        let finalCartValue: any = []

        updatedStockData.map((item: any) =>
          finalCartValue.push({stock_id: item.stockId_id, is_available: item.is_available})
        )
        formValues.diamonds = finalCartValue
      }

      if (status === 'PENDING') {
        let finalCartValue: any = []

        updatedStockData
          .filter((item: any) => item.is_available)
          .map((item: any) =>
            finalCartValue.push({stock_id: item.stockId_id, is_available: item.is_available})
          )
        formValues.diamonds = finalCartValue
      }

      console.log('formValues', formValues)

      onSubmit(formValues, data._id)
    }

    handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public onChangeStockUpdate = (stockId: string, status: string) => {
    let dataExistsIndex = -1
    if (this.state.updatedStockData && this.state.updatedStockData.length >= 1) {
      dataExistsIndex = this.state.updatedStockData.findIndex(
        (item: any) => item.stockId_id === stockId
      )
    }
    if (dataExistsIndex === -1) {
      this.setState({
        updatedStockData: [...(this.state.updatedStockData || {}), {is_available: status}],
      })
    } else if (dataExistsIndex >= 0) {
      this.state.updatedStockData[dataExistsIndex].is_available = status
      this.setState({
        updatedStockData: [...this.state.updatedStockData],
      })
    }
  }

  public calculateTotal = () => {
    const {data} = this.props
    if (data && data.stock_diamonds && data.stock_diamonds.length >= 1) {
      this.totalPrice = 0
      this.totalDiscount = 0
      data.stock_diamonds.map((invItem: any) => {
        this.totalPrice += invItem.stock_id.final_price
        this.totalDiscount += invItem.stock_id.discounts
      })
    }
    return returnPriceInThousandFormatWithCurrencySign(this.totalPrice)
  }

  public calculateAvgDiscount = () => {
    const {data} = this.props
    return returnPriceInThousandFormat(this.totalDiscount / data.stock_diamonds.length)
  }

  public calculateShipMent = () => {
    const {shipmentData} = this.state

    if (shipmentData && shipmentData.length >= 1) {
      let getShipMentData = shipmentData.find(
        (shipItem: any) =>
          shipItem.from_number < this.totalPrice && shipItem.to_number > this.totalPrice
      )
      if (getShipMentData) {
        this.shipMentPrice = getShipMentData.price
      }
    }
    return returnPriceInThousandFormatWithCurrencySign(this.shipMentPrice)
  }

  public calculateGrandTotal = () => {
    let grandTotal = this.shipMentPrice + this.totalPrice
    return returnPriceInThousandFormatWithCurrencySign(grandTotal)
  }

  public render() {
    const {data, loginUserData} = this.props
    const {updatedStockData} = this.state

    return (
      <div className='card card-custom'>
        <Formik initialValues={{}} validate={this.validateForm} onSubmit={this.handleSubmit}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }: IRenderFormikForm) => {
            return (
              <div>
                <div className='card-body'>
                  <>
                    {loginUserData?.role === 'admin' && (
                      <div className='mb-10'>
                        <h3 className='mb-10'>
                          {'Order Details'}
                          <span className='ml-5'>
                            {data?.status && (
                              <Tag
                                color={
                                  config.statusTagColor[data.status]
                                    ? config.statusTagColor[data.status]
                                    : 'default'
                                }
                              >
                                {data.status}
                              </Tag>
                            )}
                          </span>
                        </h3>
                        <div className='row mb-5'>
                          <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                            {'Name:'}
                          </div>
                          <div className='col-lg-4'>
                            {data?.client_id?.first_name && data?.client_id?.last_name
                              ? `${data.client_id.first_name} ${data.client_id.last_name}`
                              : data.client_id.first_name
                              ? data.client_id.first_name
                              : '-'}
                          </div>
                          <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                            {'Company:'}
                          </div>
                          <div className='col-lg-4'>
                            {data?.client_id?.company_name ? data.client_id.company_name : '-'}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                            {'Email:'}
                          </div>
                          <div className='col-lg-4'>
                            {data?.client_id?.email ? data.client_id.email : '-'}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className='row border-bottom'>
                      <h3 className='mb-10'>{'Stock Details'}</h3>
                      <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                        {'Stock Id'}
                      </div>
                      <div className='col-lg-4 text-dark font-weight-bold text-muted'>
                        {'Details'}
                      </div>
                      <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                        <div className='text-right'>{'Discount'}</div>
                      </div>
                      <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                        <div className='text-right'>{'$/Ct'}</div>
                      </div>
                      <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                        <div className='text-right'>{'Price'}</div>
                      </div>
                      <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                        <div className='text-center'>{'Availability'}</div>
                      </div>
                    </div>
                    <div style={{maxHeight: '70vh', overflowX: 'hidden', overflowY: 'auto'}}>
                      {updatedStockData.map(
                        (invItem: any, index: number) =>
                          invItem && (
                            <div className='row mt-10' key={index}>
                              <div className='col-lg-2 text-dark font-weight-bold'>
                                <label>
                                  <a
                                    href={`/inventory/preview/${invItem.stock_data._id}`}
                                    target='_blank'
                                  >
                                    {returnStockId(invItem.stock_data.stock_id)}
                                  </a>
                                </label>
                              </div>
                              <div className='col-lg-4 text-dark font-weight-bold'>
                                <label>{returnStockString(invItem.stock_data)}</label>
                              </div>
                              <div className='col-lg-1 text-dark font-weight-bold'>
                                <div className='text-right'>
                                  <label>{invItem.stock_data.discounts}</label>
                                </div>
                              </div>
                              <div className='col-lg-1 text-dark font-weight-bold'>
                                <div className='text-right'>
                                  <label>{invItem.stock_data.price_per_caret}</label>
                                </div>
                              </div>
                              <div className='col-lg-2 text-dark font-weight-bold'>
                                <div className='text-right'>
                                  <label>
                                    {returnPriceInThousandFormatWithCurrencySign(invItem.stock_data.final_price)}
                                  </label>
                                </div>
                              </div>
                              <div className='col-lg-2 text-dark font-weight-bold'>
                                <InputSingleSelect
                                  input={{
                                    value: invItem.is_available,
                                    id: 'status',
                                    name: 'status',
                                    options: this.stockAvailableStatus,
                                  }}
                                  placeholder='Stock Status'
                                  onChangeMethod={(value) => {
                                    this.onChangeStockUpdate(invItem.stockId_id, value)
                                  }}
                                  // label='Stock Status'
                                  error={errors}
                                  touched={touched}
                                  clearable={false}
                                  disabled={loginUserData?.role !== 'admin'}
                                />
                              </div>
                            </div>
                          )
                      )}
                    </div>
                    <div className='row border-top mt-10'>
                      <div className='col-lg-8 text-dark font-weight-bold text-muted text-end'>
                        {'Sub Total:'}
                      </div>
                      <div className='col-lg-2 text-dark font-weight-bold'>
                        <div className='text-right'>{this.calculateTotal()}</div>
                      </div>
                    </div>
                    <div className='row mt-5'>
                      <div className='col-lg-8 text-dark font-weight-bold text-muted text-end'>
                        {'Discount:'}
                      </div>
                      <div className='col-lg-2 text-dark font-weight-bold'>
                        <div className='text-right'>{`${this.calculateAvgDiscount()}(%)`}</div>
                      </div>
                    </div>
                    <div className='row mt-5'>
                      <div className='col-lg-8 text-dark font-weight-bold text-muted text-end'>
                        {'Shipment Price:'}
                      </div>
                      <div className='col-lg-2 text-dark font-weight-bold'>
                        <div className='text-right'>{this.calculateShipMent()}</div>
                      </div>
                    </div>
                    <div className='row border-bottom mt-5'>
                      <div className='col-lg-8 text-dark font-weight-bold text-muted text-end'>
                        {'Total:'}
                      </div>
                      <div className='col-lg-2 text-dark font-weight-bold'>
                        <div className='text-right'>{this.calculateGrandTotal()}</div>
                      </div>
                    </div>
                  </>

                  {/* {updatedStockData.length >= 1 &&
                            updatedStockData.map((stockItem: any, index: number) => (
                              <>
                                <div className='col-md-6 col-lg-6 col-sm-12'>
                                  <InputText
                                    input={{
                                      value: stockItem.stock_id,
                                      id: 'stock_id',
                                      name: 'stock_id',
                                    }}
                                    placeholder='Stock Id'
                                    onChange={(value: string) => {
                                      handleChange(value)
                                    }}
                                    onBlur={handleBlur}
                                    label='Stock Id'
                                    error={errors}
                                    touched={touched}
                                    disabled={true}
                                  />
                                </div>
                                <div className='col-md-6 col-lg-6 col-sm-12'>
                                  <InputSingleSelect
                                    input={{
                                      value: stockItem.is_available,
                                      id: 'status',
                                      name: 'status',
                                      options: this.stockAvailableStatus,
                                    }}
                                    placeholder='Stock Status'
                                    onChangeMethod={(value) => {
                                      this.onChangeStockUpdate(stockItem.stockId_id, value)
                                    }}
                                    label='Stock Status'
                                    error={errors}
                                    touched={touched}
                                    clearable={false}
                                    disabled={loginUserData?.role !== 'admin'}
                                  />
                                </div>
                              </>
                            ))} */}
                </div>

                <div className='card-footer'>
                  <div className='float-right'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm'
                      onClick={() => this.updateBuyRequest('CANCELLED')}
                    >
                      {'Cancel'}
                    </button>
                  </div>
                  {loginUserData?.role === 'admin' ? (
                    <>
                      <div className='float-right mr-5'>
                        <button
                          type='button'
                          className='btn btn-success btn-sm'
                          onClick={() => this.updateBuyRequest('ACCEPTED')}
                        >
                          {'Approve'}
                        </button>
                      </div>
                      <div className='float-right mr-5'>
                        <InputButtonComponent onClick={() => this.updateBuyRequest('UPDATED')}>
                          {'Update'}
                        </InputButtonComponent>
                      </div>
                    </>
                  ) : loginUserData?.role === 'client' && data.status !== 'PENDING' ? (
                    <>
                      <div className='float-right mr-5'>
                        <InputButtonComponent onClick={() => this.updateBuyRequest('PENDING')}>
                          {'Confirm'}
                        </InputButtonComponent>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            )
          }}
        </Formik>
      </div>
    )
  }
}
