/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {CartWrapper} from './containers/Cart'

export function CartPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/cart' exact={true} component={CartWrapper} />
        <Redirect to='/cart' />
      </Switch>
    </div>
  )
}
