/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createOrderData, getOrderData} from '../redux/OrderCRUD'
import {getRoleDataForDropDown} from '../../role/redux/RoleCRUD'
import * as order from '../redux/OrderRedux'
import {CreateOrderForm} from './CreateOrderForm'
import {IOrderFormValues} from './CreateOrderForm'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [roleData, setRoleData] = useState([])

  // call initial data for Form
  const callInitialData = async () => {
    try {
      let roleData = await getRoleDataForDropDown()
      if (roleData.data) {
        setRoleData(roleData.data)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callOrderData = async () => {
    try {
      let orderData = await getOrderData(0, 10)
      if (orderData.data) {
        await dispatch(order.actions.setOrderLoading(true))
        // once order data set then loading will automatically off using saga middleware
        await dispatch(order.actions.setOrderData(orderData.data.docs))
        await dispatch(order.actions.setOrderDataTotal(orderData.data.total))
        history.push('/order/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IOrderFormValues) => {
    try {
      await createOrderData(values)
      await callOrderData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      {roleData && roleData.length > 0 && (
        <CreateOrderForm
          roleData={roleData}
          onSubmit={(values: IOrderFormValues) => addSubmit(values)}
        />
      )}
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Create',
            path: '/order/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'Create',
            path: '/order/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.ORDER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
